import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { deleteUser } from '../../../api/TrustPower';
import exclamationIcon from '../../../assets/svg/exclamation-icon.svg';
import modalPropTypes from '../../../propTypes/modalPropTypes';
import LoopSpinner from '../../LoopSpinner';

const Description = styled.p`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0c1f28;
`;

const OnboardingDeleteAccountModal = ({
  show,
  onHide,
  setDeleteError,
  initialReason,
}) => {
  const [isDeleting, setIsDeleting] = useState(undefined);

  const initialReasonState = {
    reason: initialReason,
    reasonDetail: '',
  };

  // eslint-disable-next-line no-unused-vars
  const [reason, setReason] = useState(initialReasonState);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      // setDeleteError(undefined);
      setIsDeleting(true);
      let reasonStr = reason.reason;
      if (!!reason.reasonDetail) {
        reasonStr += `: ${reason.reasonDetail}`;
      }
      return await deleteUser(reasonStr, false).then(
        async () =>
          await Auth.signOut().then(() => {
            localStorage.clear();
            window.location.href = '/account-deleted';
          }),
      );
    } catch (error) {
      let errorMessage = `${error.message}`;
      if (!!error?.status) {
        errorMessage = `${errorMessage} (${error.status})`;
      }
      setDeleteError(errorMessage);
    }
  };

  const handleOnHide = () => {
    setReason(initialReasonState);
    onHide();
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="pt-0 pb-4">
        <form onSubmit={onSubmit}>
          <Container>
            <Row>
              <Col md={10} className="mb-2">
                <img
                  alt="Exclamation Icon"
                  src={exclamationIcon}
                  className="mb-4"
                />
                <h2 className="color-dark">Confirm account deletion</h2>
              </Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <Description>
                  Once your account is deleted, all of your account information
                  will be permanently gone.
                </Description>
              </Col>
            </Row>
            {isDeleting ? (
              <Row className="mb-2">
                <Col>
                  <div className="p-5">
                    <LoopSpinner />
                    <br />
                    <h3 className="mb-3 text-center">Deleting account...</h3>
                  </div>
                </Col>
              </Row>
            ) : (
              <>
                <Row className="mb-2">
                  <Col>
                    <Button
                      block
                      type="submit"
                      variant="primary"
                      disabled={isDeleting}>
                      Confirm account deletion
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      block
                      type="button"
                      variant="light"
                      onClick={handleOnHide}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </form>
      </Modal.Body>
    </Modal>
  );
};

OnboardingDeleteAccountModal.propTypes = {
  ...modalPropTypes,
};

OnboardingDeleteAccountModal.defaultProps = {
  initialReason: "Didn't complete onboarding",
};

export default OnboardingDeleteAccountModal;
