import { Spinner } from 'react-bootstrap';

const WhiteSpinner = () => (
  <Spinner
    className="ml-2 color-white"
    as="span"
    animation="border"
    size="sm"
    role="status"
    aria-hidden="true"
  />
);

export default WhiteSpinner;
