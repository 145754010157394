import { Button, Modal } from 'react-bootstrap';
import { StyledModalHeader, StyledLinkButton } from '../../Styled';
import TdasLogoV2 from '../../../assets/svg/tdas-v2.svg';
import { secondaryGradient } from '../../../theme/colors';

const Connect = ({ onNext, onHide }) => (
  <>
    <StyledModalHeader background={secondaryGradient} closeButton>
      <img src={TdasLogoV2} alt="Turn Down and Save" />
    </StyledModalHeader>
    <Modal.Body className="px-4 pb-3">
      <h1 className="mb-4 text-left">Connect Loop to your smart meter</h1>
      <p className="mb-4">
        Before we can sign you up to Turn Down and Save, Loop needs to connect
        to your smart meter.
      </p>
      <Button onClick={onNext} block>
        Connect smart meter
      </Button>
      <StyledLinkButton className="mt-3" variant="link" onClick={onHide} block>
        I'll do this later
      </StyledLinkButton>
    </Modal.Body>
  </>
);
export default Connect;
