import styled from "styled-components";
import { StyledCardsContainer, StyledLabel, StyledTitle } from "../../Styled";
import { dark, primary } from "../../../theme/colors";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomTitle = styled(StyledTitle)`
  padding-top: 3px;
  padding-left: 7px;
`;

const CustomCardsContainer = styled(StyledCardsContainer)`
  border-color: ${dark};
  margin-top: 4px;
`;

const StyledAnchor = styled.a`
  color: ${primary};
`;

const TurnDownAndSave = ({ onShowTdas, onDismiss }) => (
  <div className="mt-4">
    <StyledWrapper>
      <div className="d-flex">
        <StyledLabel>New</StyledLabel>
        <CustomTitle>Turn Down and Save</CustomTitle>
      </div>
      <div>
        <a onClick={onDismiss}>Dismiss</a>
      </div>
    </StyledWrapper>
    <CustomCardsContainer>
      Get rewarded for using less energy, at carbon intensive times.
      <br />
      <StyledAnchor onClick={onShowTdas}>Find out more</StyledAnchor>
    </CustomCardsContainer>
  </div>
);

export default TurnDownAndSave;
