/**
 * Converts a string from UPPER_SNAKE_CASE to Title Case.
 *
 * @param {string} input - The string to be converted.
 * @return {string} - The converted string in Title Case.
 */
function convertToTitleCase(input) {
  if (typeof input !== 'string') {
    throw new TypeError('Input must be a string');
  }

  return input
    .toLowerCase() // Convert the entire string to lowercase
    .split('_') // Split by underscores
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join the words with spaces
}

export default convertToTitleCase;
