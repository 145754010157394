import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  border-radius: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  padding: 0;
`;

const StyledImage = styled.img`
  margin-right: 3px;
  margin-top: -2px;
`;

const StyledAfterImage = styled.img`
  margin-left: 5px;
  margin-top: -2px;
`;

const StyledDivBefore = styled.div`
  flex-grow: 1;
  background: url(${({ beforeBg }) => beforeBg}) right center no-repeat;

  @media (min-width: 2300px) {
    background-position: left center;
  }
`;

const StyledDivAfter = styled.div`
  flex-grow: 1;
  background: url(${({ afterBg }) => afterBg}) left center no-repeat;

  @media (min-width: 2300px) {
    background-position: right center;
  }
`;

const StyledContentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 11px;
`;

const AlertBar = ({
  label,
  icon,
  onClick,
  variant,
  afterIcon = null,
  beforeBg = null,
  afterBg = null,
}) => (
  <StyledAlert onClick={onClick} variant={variant}>
    <StyledDivBefore beforeBg={beforeBg} />
    <StyledContentDiv>
      <StyledImage src={icon} alt="Image before the label" /> {label}
      {!!afterIcon && (
        <StyledAfterImage src={afterIcon} alt="Image after the label" />
      )}
    </StyledContentDiv>
    <StyledDivAfter afterBg={afterBg} />
  </StyledAlert>
);

export default AlertBar;
