import React from 'react';
import styled from 'styled-components';
import infoPrimary from '../assets/svg/info-primary.svg';
import { primary } from '../theme/colors';

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  border-radius: 4px;
  text-align: left;
  border: 1px solid ${primary};
`;

const Message = styled.p`
  font-size: 14px;
  color: #0c1f28;
  margin-bottom: 8px;
  font-weight: bold;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const Icon = styled.img`
  position: relative;
  top: -2px;
  margin-right: 8px;
`;

const SubMessage = styled.p`
  font-size: 14px;
  color: #0c1f28;
  margin-bottom: 0;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const StyledLink = styled.a`
  margin-top: 8px;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const StyledButton = styled.button`
  margin-top: 8px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  text-decoration: underline !important;
  color: #ff0085;
  display: inline-block;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const WarningPanel = ({
  title = '',
  body = '',
  linkText = '',
  linkUrl = null,
  linkAction = null,
}) => {
  return (
    <EmptyStateContainer>
      <Message>
        <Icon src={infoPrimary} alt="Info" />
        {title}
      </Message>
      <SubMessage>{body}</SubMessage>
      {linkText && linkUrl && (
        <StyledLink href={linkUrl}>{linkText}</StyledLink>
      )}
      {linkAction && linkText && !linkUrl && (
        <StyledButton onClick={linkAction}>{linkText}</StyledButton>
      )}
    </EmptyStateContainer>
  );
};

export default WarningPanel;
