import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  OnboardingContainer,
  SignOutContainer,
  AppContainer,
  SmartMeterContainer,
} from '../containers';
import { OnboardingProvider } from '../context/OnboardingContext';
import { AppProvider } from '../context/AppContext';
import { ConsentProvider } from '../context/ConsentContext';

const AppRouter = () => {
  const [isOnboarded, setIsOnboarded] = useState(false);
  const isAuthenticated = true;

  return (
    <AppProvider>
      <Router>
        <Switch>
          <Route path="/sign-out" component={SignOutContainer} />
          {!isOnboarded && (
            <Route path="/onboarding">
              <OnboardingProvider>
                <OnboardingContainer />
              </OnboardingProvider>
            </Route>
          )}
          {isAuthenticated && <Route path="/app" component={AppContainer} />}
          {isAuthenticated && (
            <Route path="/smart-meter">
              <ConsentProvider>
                <SmartMeterContainer />
              </ConsentProvider>
            </Route>
          )}
          {/* Router Switch does not support fragments so we have to use two conditionals here */}
          {!isOnboarded ? (
            <Route path="/" exact>
              <Redirect to="/onboarding" />
            </Route>
          ) : (
            <Route path="/" exact>
              <Redirect to="/app" />
            </Route>
          )}
        </Switch>
      </Router>
    </AppProvider>
  );
};

export default AppRouter;
