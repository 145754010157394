import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { dark } from '../../theme/colors';

const StyledModalHeader = styled(Modal.Header)`
  background: ${props => props.background || dark};
  height: 322px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default StyledModalHeader;
