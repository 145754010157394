import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useHandleModalPath = (
  targetPathname,
  redirectPathname,
  onOpenModal,
  modalView,
) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === targetPathname) {
      history.push(redirectPathname);
      onOpenModal(modalView);
    }
  }, [
    location.pathname,
    targetPathname,
    redirectPathname,
    onOpenModal,
    modalView,
    history,
  ]);
};

export default useHandleModalPath;
