import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { contentfulPropTypes } from '../../propTypes';
import { ConsentContext } from '../../context/ConsentContext';

const ErrorContainer = () => {
  const { consentFlow } = useContext(ConsentContext);
  const history = useHistory();
  const onRetry = () => {
    history.push('/smart-meter/' + consentFlow);
  };

  return (
    <>
      <h1>Something went wrong</h1>
      <p>
        Something happened while N3rgy were processing your MPAN and we
        weren&apos;t able to sign you up correctly.
      </p>
      <p>If you would like to try again, please click the button below.</p>
      <Button variant="primary" block type="button" onClick={onRetry}>
        Re-Enter your address
      </Button>
    </>
  );
};

ErrorContainer.propTypes = {
  ...contentfulPropTypes,
};

export default ErrorContainer;
