import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import VerificationInput from 'react-verification-input';
import { CallMade } from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import {
  registrationMethods,
  registerMpanIhdInternal,
  validationCodes,
} from '../../api/SmartMeter';
import ihdGuidExample from '../../assets/svg/ihd-guid-example.svg';
import ihdGuidExampleMobile from '../../assets/svg/ihd-guid-example-mobile.svg';
import infoOutline from '../../assets/svg/info-outline.svg';
import errorIcon from '../../assets/svg/error-icon.svg';
import { ConsentContext, ConsentFlows } from '../../context/ConsentContext';
import { SpacerHr } from '../../ui/Styled';
import { primary, error } from '../../theme/colors';
import { VerifyMethodBlock } from '../../ui';
import PaymentCard from '../../ui/Icon/PaymentCard';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import cx from 'classnames';
import { createUserTrace } from '../../api/Optimise';

const StyledH1 = styled.h1`
  margin-bottom: 16px;
`;

const WrapperDiv = styled.div`
  display: flex;
`;

const StyledImg = styled.img`
  margin-right: 6px;
  margin-top: -2px;
  width: 20px;
`;

const StyledReactMarkdown = styled(ReactMarkdown)`
  margin-top: 6px;
  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  p:last-child {
    text-align: left;
    margin-top: 10px;
  }
`;

const StyledDiv = styled.div`
  text-align: left;
`;

const StyledWrapperDiv = styled(StyledDiv)`
  border: 1px solid #afb6b9;
  border-radius: 4px;
  position: relative;
  padding: 15px 15px;
  margin-bottom: 10px;

  p {
    margin-bottom: 0;
  }
`;

const StyledCallMade = styled(CallMade)`
  font-weight: 800;
  color: ${primary};
  font-size: 1rem !important;
`;

const VerificationWrapper = styled.div`
  &.error {
    .verification-input__character {
      border-color: ${error} !important;
      background-color: ${error}22 !important;
    }
  }
`;

const ErrorDiv = styled.div`
  color: ${error};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  font-size: 12px;
  margin-top: 10px;

  a {
    color: ${error};
  }
`;

const MeterCodeContainer = () => {
  const history = useHistory();
  const {
    mpan,
    setMethod,
    consentFlow,
    moveDate,
    termsAndConditions,
    lookedUpMpanHash,
    verificationMethods,
  } = useContext(ConsentContext);
  const [isRegistering, setIsRegistering] = useState(false);
  const [showExamples, setShowExamples] = useState(false);
  const [ihdMacSuffix, setIhdMacSuffix] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isValid, setIsValid] = useState(false);
  const codeInputRef = useRef();

  useEffect(() => {
    createUserTrace('Meter Code');
  }, []);

  const onChange = event => {
    setIhdMacSuffix(event.target.value);
    if (validateMac(event.target.value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const onSubmit = value => {
    setErrorMessage(undefined);
    setMethod(registrationMethods.IHD);
    Auth.currentAuthenticatedUser().then(async user => {
      const moveInDateMonth = moveDate ? moment(moveDate).format('MM') : '';
      const moveInDateYear = moveDate ? moment(moveDate).format('YYYY') : '';
      const addressId = user.attributes.address;
      const mpanAutoFilled = lookedUpMpanHash === mpan;
      const ucIhdMacSuffix = ihdMacSuffix.toUpperCase();

      // show the spinner
      setIsRegistering(true);

      // send values
      const res = await registerMpanIhdInternal(
        mpan,
        moveInDateMonth,
        moveInDateYear,
        termsAndConditions,
        addressId,
        mpanAutoFilled,
        ucIhdMacSuffix,
        verificationMethods.ihd.details.readInventoryId,
        consentFlow === ConsentFlows.RECONFIRM,
      );

      // hide the spinner
      setIsRegistering(false);

      if (
        res.status !== 200 &&
        res.code !== validationCodes.ALREADY_REGISTERED
      ) {
        setErrorMessage(res.message);
        createUserTrace('Cannot Confirm IHD');
      } else {
        history.push(`/app/today/success`);
      }
    });
  };

  const onTryWithPaymentCard = async e => {
    e.preventDefault();
    createUserTrace('IHD With Link Back To Payment Card');
    history.push(`/smart-meter/${consentFlow}/card-details`);
  };

  const validateMac = value => {
    const ucValue = `${value}`.toUpperCase();
    return ucValue.match(/[A-F0-9]{4}/gm);
  };

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}>
      <p>
        Step <strong>3</strong> of <strong>4</strong>
      </p>
      <StyledH1>Verify your address</StyledH1>
      <div>
        <p>You can only access smart meter data if you live at the property.</p>
        <p>
          You confirm this by entering the <strong>last 4 digits</strong> of a
          code from your in-home display.
        </p>
        <p>
          Depending on your display, the code is called the{' '}
          <strong>GUID, MAC or EUI</strong>. The code may be on the unit itself,
          or found in the unit’s Settings or About menu.
        </p>
      </div>
      <WrapperDiv>
        <p>
          <StyledImg src={infoOutline} alt="Info" />
          <strong className="color-primary">Hint: </strong>
          Your code starts with{' '}
          <strong>{verificationMethods?.ihd?.details?.prefix}</strong>
        </p>
      </WrapperDiv>
      <VerificationWrapper
        className={cx({
          'meter-code-wrapper': true,
          error: !!errorMessage,
        })}>
        <VerificationInput
          ref={codeInputRef}
          length={4}
          validChars="a-fA-F0-9"
          placeholder="-"
          inputField={{
            name: 'ihdMacSuffix',
            onChange,
          }}
          container={{
            className: 'verification-input-container',
          }}
          characters={{
            className: 'verification-input-characters',
          }}
          character={{
            className: 'verification-input-character',
            classNameInactive: 'verification-input-character--inactive',
            classNameSelected: 'verification-input-character--selected',
          }}
        />
        {errorMessage && (
          <ErrorDiv>
            <StyledImg src={errorIcon} alt="Info" />
            <div>
              Something went wrong, please check the last 4 digits of the
              GUID/MAC/EUI code from your IHD and re-enter them, or{' '}
              <a
                onClick={onTryWithPaymentCard}
                href={`/smart-meter/${consentFlow}/card-details`}>
                try with a payment card
              </a>{' '}
              (you won’t be charged).
            </div>
          </ErrorDiv>
        )}
      </VerificationWrapper>
      <StyledDiv>
        Some examples where this code may be located, are shown below.
      </StyledDiv>
      <StyledDiv>
        {showExamples || (
          <div>
            <Button
              className="pl-0 py-0"
              variant="link"
              onClick={() => setShowExamples(true)}>
              Show examples
              <FontAwesomeIcon
                className="em-text pt-1 pl-2"
                icon={faChevronDown}
              />
            </Button>{' '}
          </div>
        )}
      </StyledDiv>
      {showExamples && (
        <>
          <div className="text-left mb-3">
            <Button
              className="pl-0 pt-0"
              variant="link"
              onClick={() => setShowExamples(false)}>
              Hide examples
              <FontAwesomeIcon
                className="em-text pt-1 pl-2"
                icon={faChevronUp}
              />
            </Button>{' '}
          </div>
          <div className="py-2">
            <img
              alt="IHD GUID Example"
              src={ihdGuidExample}
              className="w-100 d-none d-sm-block"
            />
            <img
              alt="IHD GUID Example"
              src={ihdGuidExampleMobile}
              className="w-100 d-block d-sm-none"
            />
          </div>
          <StyledWrapperDiv>
            <div>
              Watch a short video on where to find the code in your in-home
              display's Settings or About menu.
            </div>
            <a href="https://youtu.be/hcDRhcPBTMU" target="_blank">
              Show me the video <StyledCallMade />
            </a>
          </StyledWrapperDiv>
        </>
      )}
      {!isRegistering ? (
        <Button
          className="mt-3 mb-3"
          variant="primary"
          block
          type="submit"
          disabled={!isValid}
          onClick={onSubmit}>
          Confirm
        </Button>
      ) : (
        <Button
          className="mt-3 mb-3"
          variant="primary"
          block
          disabled={true}
          onClick={() => {}}>
          <Spinner animation="border" size="sm" />
        </Button>
      )}
      <SpacerHr label="or" />
      <VerifyMethodBlock
        featureLabel="Recommended"
        icon={PaymentCard}
        title="Use a payment card"
        body="Loop is free and you won’t be charged."
        onClick={onTryWithPaymentCard}
        buttonLabel="Verify using payment card"
        buttonVariant="secondary"
      />
    </Form>
  );
};

export default MeterCodeContainer;
