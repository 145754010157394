import { createContext, useState } from 'react';
import { usePersistedState } from '../hooks';

const OnboardingContext = createContext();

export const BATTERY_MANUFACTURERS = {
  // ALPHAESS: 'alphaeass',
  GIV_ENERGY: 'giv-energy',
  GROWATT: 'growatt',
  INSTAGEN: 'instagen',
};

const OnboardingProvider = ({ children }) => {
  const [isManualAddress, setIsManualAddress] = usePersistedState(
    'isManualAddress',
    false,
  );
  const [addressId, setAddressId] = usePersistedState('addressId', false);
  const [termsAndConditions, setTermsAndConditions] = usePersistedState(
    'termsAndConditions',
    null,
  );
  const [redirectUrl, setRedirectUrl] = usePersistedState('redirectUrl', '');
  const [hasOnboarded, setHasOnboarded] = usePersistedState(
    'hasOnboarded',
    false,
  );
  const initialUserState = {
    attributes: {
      given_name: '',
      family_name: '',
      email: '',
    },
  };
  const [currentUser, setUser] = usePersistedState(
    'currentUser',
    initialUserState,
  );
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = usePersistedState('deleteError', null);
  const [progressPercentage, setProgressPercentage] = usePersistedState(0);
  const [postcode, setPostcode] = usePersistedState('postcode', null);
  const [importTariffId, setImportTariffId] = usePersistedState(
    'importTariffId',
    null,
  );
  const [exportTariffId, setExportTariffId] = usePersistedState(
    'exportTariffId',
    null,
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isSaving, setIsSaving] = usePersistedState('isSaving', false);
  const [pvSystemSize, setPvSystemSize] = usePersistedState(
    'pvSystemSize',
    null,
  );
  const [solarPanelArrayCount, setSolarPanelArrayCount] = usePersistedState(
    'solarPanelArrayCount',
    null,
  );
  const [solarPanelArrayData, setSolarPanelArrayData] = usePersistedState(
    'solarPanelArrayData',
    null,
  );
  const [numPanels, setNumPanels] = usePersistedState('numPanels', null);
  const [hasSolarDiverter, setHasSolarDiverter] = usePersistedState(
    'hasSolarDiverter',
    null,
  );
  const [importSupplier, setImportSupplier] = usePersistedState(
    'importSupplier',
    null,
  );
  const [exportSupplier, setExportSupplier] = usePersistedState(
    'exportSupplier',
    null,
  );
  const [hasEv, setHasEv] = usePersistedState('hasEv', null);
  const [batteryManufacturer, setBatteryManufacturer] = usePersistedState(
    'batteryManufacturer',
    null,
  );
  const [manufacturerDetails, setManufacturerDetails] = usePersistedState(
    'manufacturerDetails',
    null,
  );
  const [battery, setBattery] = usePersistedState('battery', null);
  const [batteryCapacity, setBatteryCapacity] = usePersistedState(
    'batteryCapacity',
    null,
  );
  const [inverterManufacturer, setInverterManufacturer] = usePersistedState(
    'inverterManufacturer',
    null,
  );
  const [inverter, setInverter] = usePersistedState('inverter', null);
  const [hasExportLimit, setHasExportLimit] = usePersistedState(
    'hasExportLimit',
    null,
  );

  return (
    <OnboardingContext.Provider
      value={{
        isManualAddress,
        addressId,
        setAddressId,
        setIsManualAddress,
        redirectUrl,
        setRedirectUrl,
        termsAndConditions,
        setTermsAndConditions,
        hasOnboarded,
        setHasOnboarded,
        currentUser,
        setUser,
        showDeleteAccountModal,
        setShowDeleteAccountModal,
        isDeleting,
        setIsDeleting,
        deleteError,
        setDeleteError,
        showAlert,
        setShowAlert,
        alert,
        setAlert,
        isSaving,
        setIsSaving,
        importTariffId,
        setImportTariffId,
        exportTariffId,
        setExportTariffId,
        progressPercentage,
        setProgressPercentage,
        postcode,
        setPostcode,
        pvSystemSize,
        setPvSystemSize,
        solarPanelArrayCount,
        setSolarPanelArrayCount,
        solarPanelArrayData,
        setSolarPanelArrayData,
        numPanels,
        setNumPanels,
        hasSolarDiverter,
        setHasSolarDiverter,
        importSupplier,
        setImportSupplier,
        exportSupplier,
        setExportSupplier,
        hasEv,
        setHasEv,
        batteryManufacturer,
        setBatteryManufacturer,
        battery,
        setBattery,
        batteryCapacity,
        setBatteryCapacity,
        inverterManufacturer,
        setInverterManufacturer,
        manufacturerDetails,
        setManufacturerDetails,
        inverter,
        setInverter,
        hasExportLimit,
        setHasExportLimit,
      }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export { OnboardingContext, OnboardingProvider };
