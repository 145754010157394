import styled from 'styled-components';

const StyledVerifyBody = styled.div`
  text-align: left;
  margin-left: 90px;

  p {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 575px) {
    text-align: center;
    margin-left: 0;
  }
`;

export default StyledVerifyBody;
