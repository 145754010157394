import awsmobile from '../config/aws-exports.js';
import { getAuthIdToken } from '../utils/auth';

export const registrationMethods = {
  IHD: 'ihd',
  TRUSTED_CONSENT: 'trusted_consent',
};

export const validationCodes = {
  OK: 'OK',
  MPAN_FORMAT_FAILED: 'MPAN_FORMAT_FAILED',
  MPAN_NOT_FOUND: 'MPAN_NOT_FOUND',
  SYSTEM_ERROR: 'SYSTEM_ERROR',
  N3RGY_ERROR: 'N3RGY_ERROR',
  PAYMENT_VALIDATION_ERROR: 'PAYMENT_VALIDATION_ERROR',
  ALREADY_REGISTERED: 'ALREADY_REGISTERED',
};

export const validateMpan = async mpan => {
  const token = await getAuthIdToken();
  return await fetch(
    `${awsmobile.smartmeter_endpoint_auth}/mpan/${mpan}/validate`,
    {
      headers: {
        Authorization: token.getJwtToken(),
      },
    },
  ).then(res => res.json());
};

export const validateAddress = async (mpan, postcode) => {
  const token = await getAuthIdToken();
  return await fetch(
    `${awsmobile.smartmeter_endpoint_auth}/mpan/${mpan}/validate-address/${postcode}`,
    {
      headers: {
        Authorization: token.getJwtToken(),
      },
    },
  ).then(res => res.json());
};

export const getValidationMethods = async mpan => {
  const token = await getAuthIdToken();
  return await fetch(
    `${awsmobile.smartmeter_endpoint_auth}/mpan/${mpan}/validation-methods`,
    {
      headers: {
        Authorization: token.getJwtToken(),
      },
    },
  ).then(res => {
    if (res.status === 200) {
      return res.json();
    }
    return res;
  });
};

export const registerMpanIhdInternal = async (
  mpan,
  moveInDateMonth,
  moveInDateYear,
  termsAndConditions,
  addressId,
  mpanAutoFilled,
  ihdMacSuffix,
  readInventoryId,
  isReconsent = false,
) => {
  const token = await getAuthIdToken();
  const consentPath = !!isReconsent ? 'ihd-internal/reconsent' : 'ihd-internal';
  return await fetch(
    `${awsmobile.smartmeter_endpoint_auth}/user/mpan/${mpan}/${consentPath}`,
    {
      method: 'post',
      headers: {
        Authorization: token.getJwtToken(),
      },
      body: JSON.stringify({
        moveInDateMonth,
        moveInDateYear,
        termsAndConditions,
        addressId,
        mpanAutoFilled,
        ihdMacSuffix,
        readInventoryId,
      }),
    },
  ).then(async res => {
    const response = await res.json();
    return {
      status: res.status,
      message: response?.message,
      code: response?.code,
    };
  });
};

export const registerMpanTrustedConsent = async (
  mpan,
  moveInDateMonth,
  moveInDateYear,
  termsAndConditions,
  addressId,
  mpanAutoFilled,
  transactionReference,
  isReconsent = false,
) => {
  const token = await getAuthIdToken();
  const consentPath = !!isReconsent
    ? 'trusted-consent/reconsent'
    : 'trusted-consent';
  return await fetch(
    `${awsmobile.smartmeter_endpoint_auth}/user/mpan/${mpan}/${consentPath}`,
    {
      method: 'post',
      headers: {
        Authorization: token.getJwtToken(),
      },
      body: JSON.stringify({
        moveInDateMonth,
        moveInDateYear,
        termsAndConditions,
        addressId,
        mpanAutoFilled,
        transactionReference,
      }),
    },
  ).then(async res => {
    const response = await res.json();
    return {
      status: res.status,
      message: response?.message,
      code: response?.code,
    };
  });
};

export const addUserMpan = async (mpan, isReconsent = false) => {
  const token = await getAuthIdToken();
  const consentPath = !!isReconsent ? 'ihd/reconsent' : 'ihd';
  return await fetch(
    `${awsmobile.smartmeter_endpoint_auth}/user/mpan/${mpan}/${consentPath}`,
    {
      headers: {
        Authorization: token.getJwtToken(),
      },
      body: JSON.stringify({
        mpan,
      }),
      method: 'post',
    },
  ).then(res => res.json());
};

export const getUserMpans = async () => {
  const token = await getAuthIdToken();
  return await fetch(`${awsmobile.smartmeter_endpoint_auth}/user/mpan`, {
    headers: {
      Authorization: token.getJwtToken(),
    },
  }).then(res => res.json());
};
