/**
 * Retrieves a campaign object from the list of campaigns by its ID.
 *
 * @param {Array<Object>} campaigns - The array of campaign objects.
 * @param {string} campaignId - The ID of the campaign to retrieve.
 * @returns {Object|null} The campaign object if found, otherwise null.
 */
function getCampaignById(campaigns, campaignId) {
  if (!Array.isArray(campaigns)) {
    throw new Error('The first parameter must be an array.');
  }

  return (
    campaigns.find(campaign => campaign.campaign_id === campaignId) || null
  );
}

export default getCampaignById;
