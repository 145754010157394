import InformationPanel from './InformationPanel';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../../context/AppContext';
import { updateOptimiserSettings } from '../../../api/Optimise';
import BasicSpinner from '../../BasicSpinner';
import StyledSwitch from '../../Styled/StyledSwitch';

const SelfConsumptionModeSettings = ({
  loadOptimiserSettings,
  setShowError,
}) => {
  const { optimiserSettings, userSub } = useContext(AppContext);
  const [switchOn, setSwitchOn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!!optimiserSettings) {
      setSwitchOn(optimiserSettings?.battery_options.self_consumption_mode);
      setIsLoading(false);
    }
  }, [optimiserSettings]);

  const handleSwitchChange = async () => {
    const invertedSwitch = !switchOn;
    setSwitchOn(invertedSwitch);
    setIsSaving(true);
    await updateOptimiserSettings(
      {
        battery_options: { self_consumption_mode: invertedSwitch },
      },
      userSub,
    )
      .then(() => {
        loadOptimiserSettings();
      })
      .catch(e => {
        console.error(e);
        setShowError(true);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const SpinnerWrapper = styled.div`
    position: relative;
    top: 1px;
  `;

  return (
    <InformationPanel title="Self-consumption mode">
      <li>
        Loop's default mode optimises for cash savings. Self-consumption mode
        enables your home to rely more on your battery and less on the grid
        during off peak times. This will lead to slightly smaller savings.
        <div className="d-flex align-items-end">
          <StyledSwitch
            type="switch"
            id="self-consumption-mode-switch"
            className="custom-switch"
            label="Self-consumption mode"
            checked={switchOn}
            onChange={handleSwitchChange}
            disabled={isLoading || isSaving}
          />
          {isLoading ||
            (isSaving && (
              <SpinnerWrapper>
                <BasicSpinner />
              </SpinnerWrapper>
            ))}
        </div>
      </li>
    </InformationPanel>
  );
};

export default SelfConsumptionModeSettings;
