import React, { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import errorDisc from '../../assets/svg/disc/error-disc.svg';
import { contentfulPropTypes } from '../../propTypes';
import withContentfulTag from '../../utils/withContentfulTag';
import ReactMarkdown from 'react-markdown';
import { SpacerHr } from '../../ui/Styled';
import { ConsentContext } from '../../context/ConsentContext';
import { createUserTrace } from '../../api/Optimise';

const StyledH1 = styled.h1`
  margin-bottom: 25px;
`;

const StyledButton = styled(Button)`
  margin: 6px 0;
`;

const CannotConfirmContainer = ({ contentful, loading, fetched }) => {
  const history = useHistory();
  const { consentFlow } = useContext(ConsentContext);

  useEffect(() => {
    const asyncCreateUserTrace = async () => {
      await createUserTrace('Cannot Confirm CC');
    };
    asyncCreateUserTrace();
  }, []);

  const onTryDifferentCard = () => {
    history.push(`/smart-meter/${consentFlow}/card-details`);
  };
  const onContinue = () => {
    history.push(`/smart-meter/${consentFlow}/meter-code`);
  };

  return (
    !loading &&
    fetched && (
      <>
        <div className="text-center mb-3">
          <img src={errorDisc} alt="Error Disc" />
        </div>
        <StyledH1>{contentful.title}</StyledH1>
        <ReactMarkdown>{contentful.body}</ReactMarkdown>
        <StyledButton
          variant="primary"
          block
          type="button"
          onClick={onTryDifferentCard}
          className="mb-3">
          {contentful.primaryButtonText}
        </StyledButton>
        <SpacerHr label="or" />
        <StyledButton
          variant="outline-primary"
          block
          type="button"
          onClick={onContinue}>
          {contentful.secondaryButtonText}
        </StyledButton>
      </>
    )
  );
};

CannotConfirmContainer.propTypes = {
  ...contentfulPropTypes,
};

export default withContentfulTag(
  CannotConfirmContainer,
  'web_app_onboarding_cannot_confirm_page',
);
