import { useContext, useEffect, useState } from 'react';
import withPageTitle from '../../hocs/withPageTitle';
import MinimumSOCSettings from '../../ui/Dashboard/Settings/MinimumSOCSettings';
import AccountInformation from '../../ui/Dashboard/Settings/AccountInformation';
import TariffInformation from '../../ui/Dashboard/Settings/TariffInformation';
import SignOutSettings from '../../ui/Dashboard/Settings/SignOutSettings';
import AdditionalUserInformation from '../../ui/Dashboard/Settings/AdditionalUserInformation';
import SimulationModeSettings from '../../ui/Dashboard/Settings/SimulationModeSettings';
import TDASInformation from '../../ui/Dashboard/Settings/TDASInformation';
import SelfConsumptionModeSettings from '../../ui/Dashboard/Settings/SelfConsumptionModeSettings';
import { AppContext } from '../../context/AppContext';
import { getUserDetails, getOptimiserSettings } from '../../api/Optimise';
import InfoModal from '../../ui/Modal/InfoModal';

const SettingsContainer = () => {
  const {
    setUserDetails,
    setOptimiserSettings,
    userSub,
    isAdmin,
    userMpan,
    tdasIsActive,
  } = useContext(AppContext);
  const [showError, setShowError] = useState(false);

  const loadUser = () => {
    getUserDetails(userSub).then(res => {
      setUserDetails(res);
    });
  };

  const loadOptimiserSettings = () => {
    getOptimiserSettings(userSub).then(res => {
      setOptimiserSettings(res);
    });
  };

  useEffect(() => {
    loadUser();
    loadOptimiserSettings();
  }, []);

  return (
    <div>
      <InfoModal
        show={showError}
        onClose={() => setShowError(false)}
        infoContent={
          <div>
            Something went wrong and we weren't able to save your changes to the
            optimiser. Please try again and if the problem persists, please{' '}
            <a
              target="_blank"
              href="https://kb.loop.homes/kb-tickets/new"
              rel="noreferrer">
              contact support
            </a>
            .
          </div>
        }
      />
      {tdasIsActive && !userMpan && <TDASInformation />}
      {isAdmin && <AdditionalUserInformation />}
      {!isAdmin && <AccountInformation />}
      <TariffInformation />
      <MinimumSOCSettings loadUser={loadUser} setShowError={setShowError} />
      <SimulationModeSettings loadUser={loadUser} setShowError={setShowError} />
      <SelfConsumptionModeSettings
        loadOptimiserSettings={loadOptimiserSettings}
        setShowError={setShowError}
      />
      <SignOutSettings />
    </div>
  );
};

export default withPageTitle('Loop Optimise Settings', SettingsContainer);
