import { createContext, useState } from "react";
import { usePersistedState } from "../hooks";
import moment from "moment";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = usePersistedState(
    "pageTitle",
    "Loop Optimise"
  );
  const [userSub, setUserSub] = usePersistedState("userSub", null);

  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, "days").toDate()
  );
  const [savingsLoading, setSavingsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [optimiserSettings, setOptimiserSettings] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [analyticsStartDate, setAnalyticsStartDate] = usePersistedState(
    "analyticsStartDate",
    null
  );
  const [datePickerWarning, setDatePickerWarning] = useState(null);
  const [hideTdasBanner, setHideTdasBanner] = usePersistedState(
    "hideTdasBanner",
    null
  );
  const [showTdasModal, setShowTdasModal] = usePersistedState(
    "showTdasModal",
    null
  );
  const [hasShownTdasModal, setHasShownTdasModal] = usePersistedState(
    "hasShownTdasModal",
    null
  );
  const [loopUserAccount, setLoopUserAccount] = usePersistedState(
    "loopUserAccount",
    null
  );
  const [userMpan, setUserMpan] = usePersistedState("userMpan", null);
  const [showTdasAlert, setShowTdasAlert] = usePersistedState(
    "showTdasAlert",
    null
  );
  const [campaigns, setCampaigns] = usePersistedState("campaigns", null);
  const [userCampaigns, setUserCampaigns] = usePersistedState(
    "campaigns",
    null
  );
  const [tdasCampaign, setTdasCampaign] = usePersistedState("campaigns", null);
  const [tdasIsActive, setTdasIsActive] = usePersistedState(
    "tdasIsActive",
    false
  );
  const [userEvents, setUserEvents] = usePersistedState("userEvents", null);
  const [tdasModalView, setTdasModalView] = useState(null);
  const [gridEvent, setGridEvent] = useState(null);

  const state = {
    pageTitle,
    setPageTitle,
    selectedDate,
    setSelectedDate,
    savingsLoading,
    setSavingsLoading,
    userDetails,
    setUserDetails,
    userSub,
    setUserSub,
    analyticsStartDate,
    setAnalyticsStartDate,
    isAdmin,
    setIsAdmin,
    datePickerWarning,
    setDatePickerWarning,
    hideTdasBanner,
    setHideTdasBanner,
    showTdasModal,
    setShowTdasModal,
    hasShownTdasModal,
    setHasShownTdasModal,
    tdasModalView,
    setTdasModalView,
    loopUserAccount,
    setLoopUserAccount,
    showTdasAlert,
    setShowTdasAlert,
    campaigns,
    setCampaigns,
    tdasCampaign,
    setTdasCampaign,
    userCampaigns,
    setUserCampaigns,
    tdasIsActive,
    setTdasIsActive,
    userEvents,
    setUserEvents,
    userMpan,
    setUserMpan,
    optimiserSettings,
    setOptimiserSettings,
    gridEvent,
    setGridEvent,
  };

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
