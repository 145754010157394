import React, { useContext, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { SpacerHr, StyledVerifyBody, StyledVerifyTitle } from '../../ui/Styled';
import SmartMeter from '../../ui/Icon/SmartMeter';
import PaymentCard from '../../ui/Icon/PaymentCard';
import { useHistory } from 'react-router-dom';
import { ConsentContext } from '../../context/ConsentContext';
import { getValidationMethods } from '../../api/SmartMeter';
import { VerifyMethodBlock } from '../../ui';
import { createUserTrace } from '../../api/Optimise';

const StyledParagraph = styled.p`
  @media (max-width: 575px) {
    margin-bottom: 25px;
  }
`;

const StyledBlockWrapper = styled.div`
  border: 1px solid #afb6b9;
  border-radius: 4px;
  position: relative;
  padding: 25px 25px;
`;

const StyledIconWrapper = styled.div`
  position: absolute;

  @media (max-width: 575px) {
    position: relative;
    padding-bottom: 15px;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
  margin-bottom: 0;
`;

const VerifyContainer = () => {
  const { consentFlow, mpan, verificationMethods, setVerificationMethods } =
    useContext(ConsentContext);
  const history = useHistory();
  const [loadingMethods, setLoadingMethods] = useState(true);
  useEffect(() => {
    const asyncUseEffect = async () => {
      let res;
      try {
        res = await getValidationMethods(mpan);
      } catch (e) {
        console.error(e);
      }
      setLoadingMethods(false);
      if (!res?.status) {
        setVerificationMethods(res);
        if (res?.ihd?.available) {
          createUserTrace('Verify your Address: Payment Card or IHD');
        } else {
          createUserTrace('Verify your Address: No IHD');
        }
      } else {
        console.log(await res.text());
        setVerificationMethods(false);
      }
    };
    asyncUseEffect();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <p>
        Step <strong>3</strong> of <strong>4</strong>
      </p>
      <h1 className="mb-3">Verify your address</h1>
      <StyledParagraph>
        You can only access smart meter data if you live at the property. You
        can verify this with one of the methods below:
      </StyledParagraph>
      <VerifyMethodBlock
        featureLabel="Recommended"
        icon={PaymentCard}
        title="Use a payment card"
        body="Loop is free and you won't be charged."
        onClick={() => history.push(`/smart-meter/${consentFlow}/card-details`)}
        buttonLabel={'Verify using payment card'}
      />
      <SpacerHr label="or" block />
      <StyledBlockWrapper className="mt-1">
        <div className="d-relative">
          <StyledIconWrapper>
            <SmartMeter />
          </StyledIconWrapper>
        </div>
        {loadingMethods ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <>
            {verificationMethods?.ihd?.available ? (
              <div>
                <StyledVerifyTitle>
                  Use a code from your in-home display (IHD)
                </StyledVerifyTitle>
                <StyledVerifyBody>
                  On the next screen we'll take you through the process of
                  locating this code on your in-home display.
                </StyledVerifyBody>
                <StyledButton
                  variant="secondary"
                  onClick={() =>
                    history.push(`/smart-meter/${consentFlow}/meter-code`)
                  }
                  block>
                  Verify using code from IHD
                </StyledButton>
              </div>
            ) : (
              <div>
                <StyledVerifyTitle>
                  Use a code from your in-home display (IHD)
                </StyledVerifyTitle>
                <StyledVerifyBody>
                  <p>
                    Loop usually offers this verification route as well, but
                    unfortunately we can’t find a display registered to your
                    home.{' '}
                    <a
                      href="https://kb.loop.homes/what-if-i-dont-have-an-in-home-display-ihd"
                      target="_blank">
                      Find out more
                    </a>
                    .
                  </p>
                  <p>
                    {' '}
                    Please continue verification{' '}
                    <a href={`/smart-meter/${consentFlow}/card-details`}>
                      using a payment card
                    </a>
                    .
                  </p>
                </StyledVerifyBody>
              </div>
            )}
          </>
        )}
      </StyledBlockWrapper>
    </>
  );
};

export default VerifyContainer;
