/* eslint-disable max-len */
const SmartMeter = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={69}
    height={49}
    {...props}>
    <defs>
      <path id="sm-a" d="M0 0h69v48.748H0z" />
      <path id="sm-c" d="M0 48.748h69V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="sm-b" fill="#fff">
        <use xlinkHref="#sm-a" />
      </mask>
      <path
        fill="#0C1F28"
        d="M62.389 48.748H6.61A6.612 6.612 0 0 1 0 42.137V6.61A6.611 6.611 0 0 1 6.611 0H62.39A6.611 6.611 0 0 1 69 6.611v35.526a6.612 6.612 0 0 1-6.611 6.611"
        mask="url(#b)"
      />
      <mask id="sm-d" fill="#fff">
        <use xlinkHref="#sm-c" />
      </mask>
      <path
        fill="#8E7EAC"
        mask="url(#d)"
        d="M8.824 34.357h51.352V6.977H8.824z"
      />
      <path
        fill="#584380"
        mask="url(#d)"
        d="M8.824 12.847h51.352v-5.87H8.824z"
      />
      <path
        fill="#8E7EAC"
        d="M34.312 14.244h-22.49v-3.497c0-.93.754-1.686 1.685-1.686h19.12c.93 0 1.685.755 1.685 1.686v3.497Z"
        mask="url(#d)"
      />
      <path
        fill="#584380"
        d="m34.312 24.946-6.771-6.77a9.576 9.576 0 0 1 13.542 0l-6.771 6.77Z"
        mask="url(#d)"
      />
      <path
        fill="#BAA7C4"
        d="m34.312 24.946-6.771 6.772a9.576 9.576 0 0 1 0-13.543l6.771 6.771Z"
        mask="url(#d)"
      />
      <path
        fill="#FF0080"
        d="m34.312 24.946 6.771-6.77a9.576 9.576 0 0 1 0 13.542l-6.771-6.772Z"
        mask="url(#d)"
      />
      <path
        fill="#0C1F28"
        d="M28.81 24.946a5.502 5.502 0 1 1 11.004 0 5.502 5.502 0 0 1-11.004 0"
        mask="url(#d)"
      />
      <path
        fill="#FFF"
        d="M32.341 24.946a1.97 1.97 0 1 1 3.942 0 1.97 1.97 0 0 1-3.942 0"
        mask="url(#d)"
      />
      <path
        fill="#FFF"
        d="m34.927 25.561-7.668 6.873c-.286.252-.688-.144-.435-.434l6.873-7.669c.842-.871 2.103.384 1.23 1.23"
        mask="url(#d)"
      />
      <path
        fill="#8E7EAC"
        d="M13.81 43.583c0 .575-.47 1.044-1.045 1.044H9.868c-.574 0-1.044-.47-1.044-1.044v-2.897c0-.574.47-1.044 1.044-1.044h2.897c.575 0 1.044.47 1.044 1.044v2.897zm46.366 0c0 .575-.47 1.044-1.044 1.044h-2.897c-.575 0-1.044-.47-1.044-1.044v-2.897c0-.574.47-1.044 1.044-1.044h2.897c.574 0 1.044.47 1.044 1.044v2.897zm-23.371-1.448a2.493 2.493 0 1 1-4.986 0 2.493 2.493 0 0 1 4.986 0M20.69 42.095l4.249 2.454v-4.907zM48.122 42.095l-4.249 2.454v-4.907z"
        mask="url(#d)"
      />
    </g>
  </svg>
);

/* eslint-enable max-len */
export default SmartMeter;
