import InformationPanel from "./InformationPanel";

const TDASInformation = () => (
  <InformationPanel title="Turn Down and Save" loadingLines={2}>
    <div>Get rewarded for using less energy, at carbon intensive times.</div>
    <div>
      <a href="/app/today/tdas">Get started</a>
    </div>
  </InformationPanel>
);

export default TDASInformation;
