import styled from 'styled-components';

const StyledVerifyTitle = styled.div`
  text-align: left;
  margin-left: 90px;
  font-weight: bold;

  @media (max-width: 575px) {
    text-align: center;
    margin-left: 0;
  }
`;

export default StyledVerifyTitle;
