import React, { useContext, useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import cx from 'classnames';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import stripeConfig from '../../config/stripe';
import { parseAddressForStripe } from '../../utils/parseAddress';
import { checkCardValidation, initiateAddCard } from '../../api/BillingGateway';
import ErrorLabel from '../../ui/ErrorLabel';
import LoopSpinner from '../../ui/LoopSpinner';
import { ConsentContext } from '../../context/ConsentContext';
import { createUserTrace } from '../../api/Optimise';

const stripePromise = loadStripe(stripeConfig.pk);

const StyledH3 = styled.h3`
  font-weight: bold;
  font-size: 16px;
`;

const StyledWrapperDiv = styled.div`
  .StripeElement--invalid {
    border-color: #ff5a35;
  }

  .StripeElement--complete {
    border-color: #3cba92;
  }
`;

const validationSchema = Yup.object().shape({
  cardName: Yup.string().trim().required('Please enter the name on your card.'),
});

const stripeOptions = {
  style: {
    base: {
      iconColor: '#495057',
      color: '#495057',
      fontWeight: 400,
      fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#495057',
      },
      '::placeholder': {
        color: '#afb6b9',
      },
    },
    invalid: {
      iconColor: '#ff5a35',
      color: '#ff5a35',
    },
  },
};

let initialCardName = '';

const CardDetailsContainer = () => {
  const history = useHistory();
  const { setPaymentMethodId, consentFlow } = useContext(ConsentContext);
  const [user, setUser] = useState(null);
  const [errors, setErrors] = useState({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(undefined);

  useEffect(() => {
    createUserTrace('Proof of Address');
  }, []);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      setUser(user);
      initialCardName =
        user?.attributes?.given_name && user?.attributes?.family_name
          ? `${user?.attributes?.given_name} ${user?.attributes?.family_name}`
          : '';
    });
  }, [setUser]);

  const checkForErrors = e => {
    const updateErrors = errors;
    if (e.error) {
      updateErrors[e.elementType] = e.error;
    } else if (e.complete) {
      updateErrors[e.elementType] = null;
    }
    setErrors(updateErrors);
  };

  const addAlert = (variant, message) => {
    setShowAlert(true);
    setAlert({ variant, message });
  };

  const hideAlert = () => {
    setShowAlert(false);
    setAlert(undefined);
  };

  const CardCaptureForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const onSubmit = async values => {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const address = parseAddressForStripe(user.attributes.address);
      const cardholderName = values.cardName;

      // create payment method
      const createPaymentMethodPayload = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: cardholderName,
          address: address,
        },
      });

      setPaymentMethodId(createPaymentMethodPayload.paymentMethod.id);
      setIsSaving(true);

      // pass card id to the backend to setup intent
      const initiateAddCardResult = await initiateAddCard(
        createPaymentMethodPayload.paymentMethod.id,
      );

      // confirm card setup
      const confirmSetupResult = await stripe.confirmCardSetup(
        initiateAddCardResult.clientSecret,
      );

      if (confirmSetupResult?.error) {
        setShowAlert(true);
        addAlert('danger', confirmSetupResult.error.message);
      } else {
        // check the address confirmation status
        const cardValidationResult = await checkCardValidation();

        if (!cardValidationResult.cardValidated) {
          history.push(`/smart-meter/${consentFlow}/cannot-confirm`);
        } else {
          history.push(`/smart-meter/${consentFlow}/connecting`);
        }
      }

      setIsSaving(false);
    };

    return (
      !!user && (
        <Formik
          validationSchema={validationSchema}
          initialValues={{ cardName: initialCardName }}
          onSubmit={onSubmit}
          validateOnChange
          validateOnMount
          validateOnBlur>
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              {showAlert && alert && (
                <div className="py-2">
                  <Alert
                    variant={alert.variant}
                    onClose={() => hideAlert()}
                    dismissible>
                    Something went wrong - {alert.message}
                  </Alert>
                </div>
              )}
              <Field name="cardName">
                {({ field, meta }) => (
                  <Form.Group
                    controlId="cardName"
                    className={`text-left ${cx({
                      'd-none': isSubmitting || isSaving,
                    })}`}>
                    <Form.Label>Name on card</Form.Label>
                    <Form.Control
                      type="text"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      isValid={!meta.error && meta.touched}
                      placeholder="Name on card"
                      className={cx({ error: !!meta.error })}
                    />
                    {meta.error && meta.touched && (
                      <ErrorLabel label={meta.error} />
                    )}
                  </Form.Group>
                )}
              </Field>
              <StyledWrapperDiv
                className={`text-left ${cx({
                  'd-none': isSubmitting || isSaving,
                })}`}>
                <StyledH3 className="mt-3">Card number</StyledH3>
                <CardNumberElement
                  className="form-control pt-2"
                  options={stripeOptions}
                  onChange={checkForErrors}
                  onBlur={checkForErrors}
                />
                {errors.cardNumber && (
                  <ErrorLabel label={errors.cardNumber.message} />
                )}
                <div className="d-flex w-100 justify-content-between">
                  <span className="w-50 mr-1">
                    <StyledH3 className="mt-3">Expiry date</StyledH3>
                    <CardExpiryElement
                      className="form-control pt-2"
                      options={stripeOptions}
                      onChange={checkForErrors}
                      onBlur={checkForErrors}
                    />
                    {errors.cardExpiry && (
                      <ErrorLabel label={errors.cardExpiry.message} />
                    )}
                  </span>
                  <span className="w-50 ml-1">
                    <StyledH3 className="mt-3">Security code</StyledH3>
                    <CardCvcElement
                      className="form-control pt-2"
                      options={stripeOptions}
                      onChange={checkForErrors}
                      onBlur={checkForErrors}
                    />
                    {errors.cardCvc && (
                      <ErrorLabel label={errors.cardCvc.message} />
                    )}
                  </span>
                </div>
                <Button
                  variant="primary"
                  block
                  type="submit"
                  disabled={isSubmitting || isSaving}>
                  {isSubmitting || isSaving ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Continue'
                  )}
                </Button>
              </StyledWrapperDiv>
              {(isSubmitting || isSaving) && <LoopSpinner />}
            </Form>
          )}
        </Formik>
      )
    );
  };

  return (
    <>
      <div>
        <p>
          Step <strong>3</strong> of <strong>4</strong>
        </p>
        <h1 className="mb-3">Proof of address</h1>
        <div>
          <p>
            Loop takes your privacy seriously. You can only access smart meter
            data if you live at the property.
          </p>
          <p>
            We confirm this by checking the address you entered against a credit
            or debit card. Loop is free and you won't be charged.{' '}
            <a
              href="https://kb.loop.homes/why-do-i-need-to-enter-my-bank-card-when-registering-my-smart-meter"
              target="_blank">
              Why does Loop need my card details?
            </a>
          </p>
          <p>
            If you can, enter a card registered at the address entered earlier.
            If the card is registered at another address a second step will
            follow to confirm your right to access the data.
          </p>
        </div>
        <Elements stripe={stripePromise}>
          <CardCaptureForm />
        </Elements>
      </div>
    </>
  );
};

export default CardDetailsContainer;
