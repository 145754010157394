import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { StyledVerifyBody, StyledVerifyTitle } from './Styled';

const StyledBlockWrapper = styled.div`
  border: 1px solid #afb6b9;
  border-radius: 4px;
  position: relative;
  padding: 25px 25px;
`;

const StyledLabelWrapper = styled.div`
  position: absolute;
  top: -14px;
  text-align: right;
  width: 100%;
  padding-right: 50px;

  @media (max-width: 575px) {
    text-align: center;
    padding-right: 0;
    left: 0;
  }
`;

const StyledLabelBorder = styled.span`
  border: 3px solid #fff;
`;

const StyledLabel = styled.span`
  border-radius: 4px;
  color: white;
  font-weight: bold;
  padding: 6px 10px;
  font-size: 10px;
  display: inline;
`;

const StyledIconWrapper = styled.div`
  position: absolute;

  @media (max-width: 575px) {
    position: relative;
    padding-bottom: 15px;
  }
`;

const StyledReactMarkdown = styled(ReactMarkdown)`
  text-align: left;
  margin-left: 90px;

  p {
    margin-bottom: 5px;
  }

  @media (max-width: 575px) {
    text-align: center;
    margin-left: 0;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
  margin-bottom: 0;
`;

const VerifyMethodBlock = ({
  featureLabel,
  icon: Icon,
  markdown,
  title,
  body,
  onClick,
  buttonLabel,
  buttonVariant = 'primary',
}) => (
  <StyledBlockWrapper className="mb-3">
    <StyledLabelWrapper>
      <StyledLabelBorder>
        <StyledLabel className="background-success-gradient">
          {!!featureLabel && featureLabel}
        </StyledLabel>
      </StyledLabelBorder>
    </StyledLabelWrapper>
    <div className="d-relative">
      <StyledIconWrapper>
        <Icon />
      </StyledIconWrapper>
      {markdown && <StyledReactMarkdown>{markdown}</StyledReactMarkdown>}
      {title && <StyledVerifyTitle>{title}</StyledVerifyTitle>}
      {body && <StyledVerifyBody>{body}</StyledVerifyBody>}
    </div>
    <StyledButton block onClick={onClick} variant={buttonVariant}>
      {buttonLabel}
    </StyledButton>
  </StyledBlockWrapper>
);

export default VerifyMethodBlock;
