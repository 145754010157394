import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faYoutube,
  faFacebookF,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const icons = {
  facebook: faFacebookF,
  twitter: faTwitter,
  youtube: faYoutube,
};

const StyledLink = styled.a`
  font-size: 30px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 12px 14px 10px;
  border-radius: 40px;

  &:hover {
    color: #afb6b9;
  }
`;

const SocialIcon = ({ href, icon }) => (
  <StyledLink href={href} className="ml-3">
    <FontAwesomeIcon icon={icons[icon]} fixedWidth />
  </StyledLink>
);

SocialIcon.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default SocialIcon;
