import styled from 'styled-components';
import { primary, white } from '../../theme/colors';

const StyledLabel = styled.div`
  color: ${white};
  background: ${primary};
  border-radius: 4px;
  display: inline-block;
  font-weight: bold;
  padding: 1px 7px 0;
  height: 25px;
`;

export default StyledLabel;
