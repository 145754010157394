import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getUserDetails } from '../api/Optimise';
import infoWhite from '../assets/svg/info-white.svg';
import { AppContext } from '../context/AppContext';
import { dark, light, offWhite, white } from '../theme/colors';

const WrapperDiv = styled.div`
  z-index: 1002;
  position: relative;
  background: ${dark};
  color: ${white};
  padding: 14px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${light}99;

  @media (min-width: 576px) {
    padding: 14px calc(1rem + 15px);
  }
`;

const StyledDropdownArrow = styled.div`
  transition: transform 0.3s ease;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
  margin-left: 6px;

  @media (min-width: 768px) {
    margin-left: 12px;
  }

  svg {
    width: 18px;
  }
`;

const StyledImg = styled.img`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
`;

const StyledDropdownContainer = styled.div`
  display: inline-block;
`;

const StyledDropdownButton = styled.button`
  background: none;
  border: none;
  color: ${white};
  cursor: pointer;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }
`;

const StyledDropdownMenu = styled.div`
  transition: all 0.3s ease;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-10px)')};
  position: absolute;
  top: 40px;
  left: 15px;
  margin-top: 8px;
  background: ${offWhite};
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  padding: 16px;
  z-index: 9999;
  width: calc(100vw - 30px);
  color: ${dark};

  @media (min-width: 768px) {
    width: 350px;
    left: initial;
    right: 20px;
  }
`;

const FullWidthDivider = styled.hr`
  margin: 20px 0;
  border: 0;
  border-top: 1px solid ${light}99;
`;

const StyledUserSub = styled.span`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }
`;

const StyledUserAvatar = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  svg {
    width: 20px;
  }
`;

const AdminBar = ({ user, onSignOut, onViewInfo }) => {
  const history = useHistory();
  const { userSub } = useContext(AppContext);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to dropdown

  useEffect(() => {
    if (!!userSub) {
      getUserDetails(userSub).then(user => setSelectedUser(user));
    }
  }, [userSub]);

  useEffect(() => {
    // Close dropdown if clicked outside
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <WrapperDiv>
      <div>
        <StyledImg src={infoWhite} alt="Info" />
        You’re in Admin Mode.
      </div>
      <div>
        <StyledDropdownContainer ref={dropdownRef}>
          <StyledDropdownButton onClick={toggleDropdown}>
            <StyledUserSub>
              {selectedUser?.user_sub || user?.attributes?.email || 'User'}
            </StyledUserSub>
            <StyledUserAvatar>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </StyledUserAvatar>

            <StyledDropdownArrow open={dropdownOpen}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="3"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </StyledDropdownArrow>
          </StyledDropdownButton>
          <StyledDropdownMenu open={dropdownOpen}>
            <div className="font-weight-bold">
              {selectedUser?.user_sub || user?.attributes?.email}
            </div>
            {userSub && (
              <div onClick={() => history.push('/app/settings')}>
                View more user info
              </div>
            )}
            <div onClick={() => history.push('/app/admin')}>
              Change to a different user
            </div>
            <FullWidthDivider />
            <div onClick={() => onSignOut(history)}>Sign Out</div>
          </StyledDropdownMenu>
        </StyledDropdownContainer>
      </div>
    </WrapperDiv>
  );
};

export default AdminBar;
