import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  registerMpanTrustedConsent,
  registrationMethods,
  validationCodes,
} from '../../api/SmartMeter';
import LoopSpinner from '../../ui/LoopSpinner';
import { ConsentContext, ConsentFlows } from '../../context/ConsentContext';
import { createUserTrace } from '../../api/Optimise';

const ConnectingContainer = () => {
  const history = useHistory();
  const {
    moveDate,
    mpan,
    termsAndConditions,
    lookedUpMpanHash,
    setMethod,
    paymentMethodId,
    hasOnboarded,
    hasRegisterMpanTrustedConsent,
    setHasRegisterMpanTrustedConsent,
    consentFlow,
  } = useContext(ConsentContext);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isConnecting, setIsConnecting] = useState(true);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const successPath = `/app/today/success`;

  useEffect(() => {
    createUserTrace('Connecting Smart Meter');
  }, []);

  useEffect(() => {
    if (hasOnboarded) {
      history.push(successPath);
    } else {
      Auth.currentAuthenticatedUser().then(async user => {
        const moveInDateMonth = moveDate ? moment(moveDate).format('MM') : '';
        const moveInDateYear = moveDate ? moment(moveDate).format('YYYY') : '';
        const addressId = user.attributes.address;
        const mpanAutoFilled = lookedUpMpanHash === mpan;
        const transactionReference = paymentMethodId;

        let trustedConsentResult;

        // Don't reattempt trusted consent if we have already tried
        if (!hasRegisterMpanTrustedConsent) {
          setHasRegisterMpanTrustedConsent(true);
          trustedConsentResult = await registerMpanTrustedConsent(
            mpan,
            moveInDateMonth,
            moveInDateYear,
            termsAndConditions,
            addressId,
            mpanAutoFilled,
            transactionReference,
            consentFlow === ConsentFlows.RECONFIRM,
          );
        }

        setIsConnecting(false);

        // if data to energy is good, we go to success or we go to cannot confirm
        if (
          hasRegisterMpanTrustedConsent ||
          trustedConsentResult?.status === 200
        ) {
          setMethod(registrationMethods.TRUSTED_CONSENT);
          history.push(successPath);
        } else {
          setHasRegisterMpanTrustedConsent(false);
          addAlert('danger', trustedConsentResult.message);
          setError(true);
          setAlreadyRegistered(
            trustedConsentResult.code === validationCodes.ALREADY_REGISTERED,
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    lookedUpMpanHash,
    moveDate,
    mpan,
    paymentMethodId,
    setMethod,
    termsAndConditions,
  ]);

  const addAlert = (variant, message) => {
    setShowAlert(true);
    setAlert({ variant, message });
  };

  const hideAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <h1 className="mb-3">Connecting to your smart meter</h1>
      {isConnecting && (
        <>
          <div className="mb-3">
            <LoopSpinner />
          </div>
          <p>
            Please wait a moment while we attempt to connect to your smart
            meter.
          </p>
        </>
      )}
      {showAlert && alert && (
        <div className="pt-4">
          <Alert
            variant={alert.variant}
            onClose={() => hideAlert()}
            dismissible>
            Something went wrong - {alert.message}
          </Alert>
        </div>
      )}
      {error && (
        <>
          <div className="px-4 pt-2">
            We weren't able to verify your address.
            <br />
            Please try again.
          </div>
          <div>
            <Button
              onClick={() =>
                history.push('/smart-meter/onboarding/verify-address')
              }
              className="px-4 mb-3"
              variant="primary">
              Try Again
            </Button>
          </div>
        </>
      )}
      {alert && alreadyRegistered && (
        <Button
          onClick={() => history.push(successPath)}
          className="px-4"
          variant="primary">
          Continue
        </Button>
      )}
    </>
  );
};

export default ConnectingContainer;
