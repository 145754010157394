import { getAuthIdToken } from './auth';

const getToken = async () => (await getAuthIdToken()).getJwtToken();

const apiRequest = async (endpoint, userSub = null, method = 'GET', body) => {
  const params = {
    headers: {
      Authorization: await getToken(),
      'user-sub': userSub,
    },
    method: method,
  };
  if (method !== 'GET' && !!body) {
    params.body = JSON.stringify(body);
  }
  return await fetch(endpoint, params)
    .then(async res => {
      if (res.status !== 200) {
        if (!res?.ok) {
          const resultObject = await res.json();
          const err = new Error(
            resultObject?.message || `There was a request error`,
          );
          err.response = res;
          err.status = res.status;
          throw err;
        }
      }
      const text = await res.text();
      if (!text) {
        return;
      }
      try {
        return JSON.parse(text);
      } catch (e) {
        return text;
      }
    })
    .catch(e => {
      if (e.status !== 404) {
        console.error(e);
      }
      throw e;
    });
};

export default apiRequest;
