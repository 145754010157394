/**
 * Calculates the start time and end time from a collection of events.
 *
 * @param {Array<Object>} events - An array of objects containing a `time` property (Unix timestamp).
 * @returns {Object} An object containing the `startTime` (lowest time) and `endTime` (highest time + 30 minutes).
 */
function getStartAndEndTime(events) {
  if (!Array.isArray(events) || events.length === 0) {
    throw new Error("Input must be a non-empty array of event objects.");
  }

  const times = events.map(event => event.time);

  const startTime = Math.min(...times);
  const endTime = Math.max(...times) + 30 * 60; // Add 30 minutes (in seconds)

  return { startTime, endTime };
}

export default getStartAndEndTime;
