import { getAuthIdToken } from '../utils/auth';
import awsmobile from '../config/aws-exports.js';

export const initiateAddCard = async paymentMethodId => {
  const token = await getAuthIdToken();
  const jwtToken = token.getJwtToken();
  return await fetch(`${awsmobile.billing_endpoint_auth}/user/card`, {
    headers: {
      Authorization: jwtToken,
    },
    body: JSON.stringify({
      paymentMethodId,
    }),
    method: 'post',
  }).then(res => res.json());
};

export const checkCardValidation = async () => {
  const token = await getAuthIdToken();
  const jwtToken = token.getJwtToken();
  return await fetch(
    `${awsmobile.billing_endpoint_auth}/user/card/validation`,
    {
      headers: {
        Authorization: jwtToken,
      },
      method: 'get',
    },
  ).then(res => res.json());
};
