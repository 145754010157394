import styled from 'styled-components';

const StyledCardsContainer = styled.div`
  margin-top: 16px;
  border: 1px solid #afb6b9;
  border-radius: 8px;
  padding: 16px;
`;

export default StyledCardsContainer;
