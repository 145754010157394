import { Button, Modal } from 'react-bootstrap';
import { StyledModalHeader } from '../../Styled';
import TdasLogoV2 from '../../../assets/svg/tdas-v2.svg';
import { successGradient } from '../../../theme/colors';
import { useContext, useEffect, useState } from 'react';
import WhiteSpinner from '../../WhiteSpinner';
import {
  getUserGridCampaigns,
  setUserGridCampaigns,
  tdasCampaignId,
} from '../../../api/Optimise';
import ErrorLabel from '../../ErrorLabel';
import { AppContext } from '../../../context/AppContext';

const Success = ({ onHide }) => {
  const { setUserEvents } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    setError(null);
    setUserGridCampaigns(tdasCampaignId)
      .then(() => {
        getUserGridCampaigns().then(res => setUserEvents(res));
      })
      .catch(error => {
        console.error(error);
        setError(error.message);
      })
      .finally(() => setLoading(false));
  }, []);
  return (
    <>
      <StyledModalHeader background={successGradient} closeButton>
        <img src={TdasLogoV2} alt="Turn Down and Save" />
      </StyledModalHeader>
      <Modal.Body className="px-4 pb-3">
        <h1 className="mb-4 text-left">
          You’re signed up to Turn Down and Save
        </h1>
        <p className="mb-4">
          Thanks for opting in. We’ll let you know when the first Turn Down and
          Save event is.
        </p>
        {error && (
          <div className="pt-0 mb-3">
            <ErrorLabel label={error} />
          </div>
        )}
        <Button className="mb-3" onClick={onHide} block>
          {loading ? <WhiteSpinner /> : 'Close'}
        </Button>
      </Modal.Body>
    </>
  );
};
export default Success;
