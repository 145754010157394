import React, { useEffect, useState } from 'react';
import withContentfulTag from '../../utils/withContentfulTag';
import ReactMarkdown from 'react-markdown';
import errorDisc from '../../assets/svg/disc/error-disc.svg';
import { Button } from 'react-bootstrap';
import { contentfulPropTypes } from '../../propTypes';
import styled from 'styled-components';
import { usePersistedState } from '../../hooks';
import OnboardingDeleteAccountModal from '../../ui/Modal/DeleteAccount/OnboardingDeleteAccountModal';
import { black, solarGradientReverse, white } from '../../theme/colors';
import SolarIconDisc from '../../ui/Icon/SolarIconDisc';
import { createUserTrace } from '../../api/Optimise';

const CtaWrapperDiv = styled.div`
  position: relative;
  border-radius: 4px;
  background: ${solarGradientReverse};
  margin: 0 -25px 0 -25px;
  padding: 30px;
`;

const InnerDiv = styled.div`
  background: ${white};
  padding: 30px 15px 15px 15px;
  border-radius: 4px;
`;

const StyledReactMarkdown = styled(ReactMarkdown)`
  h2 {
    color: ${black};
  }
`;

const StyledDeleteAccountParagraph = styled.p`
  position: relative;
  top: 4px;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0;

  button {
    font-size: 14px;
    line-height: 1.5;
  }
`;

const NotCompatibleContainer = ({ loading, fetched, contentful }) => {
  useEffect(() => {
    const asyncCreateUserTrace = async () => {
      createUserTrace('Smart Meter Not Compatible V2');
    };
    asyncCreateUserTrace();
  }, []);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = usePersistedState(
    'deleteError',
    undefined,
  );
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

  return (
    !loading &&
    fetched && (
      <>
        <OnboardingDeleteAccountModal
          show={showDeleteAccountModal}
          onHide={() => setShowDeleteAccountModal(false)}
          setIsDeleting={setIsDeleting}
          deleteError={deleteError}
          setDeleteError={setDeleteError}
          initialReason="Didn't complete onboarding. Smart meter incompatible."
        />
        <p>
          <img src={errorDisc} alt="Warning" />
        </p>
        <h1 className="pb-3">{contentful?.title}</h1>
        <ReactMarkdown className="pb-4">{contentful?.body}</ReactMarkdown>
        <SolarIconDisc top={-30} />
        <CtaWrapperDiv>
          <InnerDiv>
            {contentful?.bodyEnd && (
              <StyledReactMarkdown>{contentful.bodyEnd}</StyledReactMarkdown>
            )}
            {contentful?.primaryButtonText && (
              <Button
                className="mb-0 mt-2"
                onClick={async () => {
                  await createUserTrace('Smart Meter Not Compatible Solar CTA');
                  return (window.location.href = contentful?.link);
                }}
                block>
                {contentful.primaryButtonText}
              </Button>
            )}
          </InnerDiv>
        </CtaWrapperDiv>

        <div className="mt-3">
          <StyledDeleteAccountParagraph>
            No longer want a Loop account?{' '}
            <Button
              variant="inline-link"
              onClick={() => setShowDeleteAccountModal(true)}
              disabled={isDeleting}>
              Delete it here
            </Button>
          </StyledDeleteAccountParagraph>
        </div>
      </>
    )
  );
};

NotCompatibleContainer.propTypes = {
  ...contentfulPropTypes,
};

export default withContentfulTag(
  NotCompatibleContainer,
  'web_app_onboarding_not_compatible_page_v2',
);
