import styled from 'styled-components';
import PropTypes from 'prop-types';
import SolarIcon from '../../assets/svg/icon/solar.svg';
import { solarGradientReverse, white } from '../../theme/colors';

const IconWrapper = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  width: 100%;
  z-index: 100;
`;
const IconDisc = styled.div`
  background: ${solarGradientReverse};
  border-radius: 50%;
  border: 3px solid ${white};
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 85px;
`;

const SolarIconDisc = ({ top }) => (
  <div className="position-relative">
    <IconWrapper top={top}>
      <IconDisc>
        <img alt="Solar icon" src={SolarIcon} />
      </IconDisc>
    </IconWrapper>
  </div>
);

SolarIconDisc.propTypes = {
  top: PropTypes.number,
};

SolarIconDisc.defaultProps = {
  top: 0,
};

export default SolarIconDisc;
