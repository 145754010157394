function getTariffById(id, tariffs) {
  return tariffs.find(tariff => tariff.id === id) || null;
}

const getTariffsForSupplier = (supplierName, tariffs) => {
  const supplierTariffs = [];
  tariffs.forEach(tariff => {
    if (supplierName === tariff.supplier_name) {
      supplierTariffs.push(tariff);
    }
  });
  return supplierTariffs;
};

const getSupplierNames = tariffs => {
  const supplierNames = [];
  tariffs.forEach(({ supplier_name }) => {
    if (!supplierNames.includes(supplier_name)) {
      supplierNames.push(supplier_name);
    }
  });
  return supplierNames;
};

export { getTariffById, getTariffsForSupplier, getSupplierNames };
