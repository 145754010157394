import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import cx from 'classnames';
import moment from 'moment';
import ErrorLabel from '../../ui/ErrorLabel';
import getDateOptions from '../../utils/getDateOptions';
import { RadioWrapper, SpacerHr } from '../../ui/Styled';
import { ConsentContext } from '../../context/ConsentContext';
import { Auth } from 'aws-amplify';
import getAddressFromId from '../../utils/getAddressFromId';
import { createUserTrace } from '../../api/Optimise';

const moveYears = getDateOptions(2, 'years', 'YYYY');
const moveMonths = getDateOptions(12, 'months', 'MMMM');

const MoveDateContainer = () => {
  const { consentFlow, setMoveDate } = useContext(ConsentContext);
  const history = useHistory();
  const [touched, setTouched] = useState(false);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      setAddress(getAddressFromId(user.attributes.address));
    });
  }, []);

  const onSubmitMoveDate = values => {
    if (values.moveRadio === 'more-than') {
      setMoveDate(undefined);
    } else {
      setMoveDate(
        moment(`${values.moveDateMonth} ${values.moveDateYear}`, 'MMMM YYYY'),
      );
    }
    createUserTrace('Move Date', values);
    history.push(`/smart-meter/${consentFlow}/mpan`);
  };

  const schema = Yup.object().shape({
    moveRadio: Yup.mixed().oneOf(['more-than', 'less-than']).required(),
    moveDateMonth: Yup.mixed().oneOf(moveMonths, 'Please select the month'),
    moveDateYear: Yup.mixed().oneOf(moveYears, 'Please select the year'),
  });

  const shouldDisableSubmit = (values, touched) => {
    if (!touched) {
      return true;
    }
    if (values.moveRadio === 'more-than') {
      return false;
    }
    if (
      values.moveRadio === 'less-than' &&
      moveYears.includes(values.moveDateYear) &&
      moveMonths.includes(values.moveDateMonth)
    ) {
      if (
        moment(
          `${values.moveDateMonth} ${values.moveDateYear}`,
          'MMMM YYYY',
        ).isAfter()
      ) {
        return true;
      }
      return false;
    }
    if (
      moment(
        `${values.moveDateMonth} ${values.moveDateYear}`,
        'MMMM YYYY',
      ).isBefore()
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <p>
        Step <strong>1</strong> of <strong>4</strong>
      </p>
      <h1 className="mb-3">When did you move to your address?</h1>
      <div>
        <p>
          Loop can collect up to 13 months of data from your smart meter. We
          don't want to collect data from before you lived in the property.
        </p>
        <p>When did you move in?</p>
        <SpacerHr />
        <div>
          <strong>Your address</strong>
          <br />
          {<p>{address}</p> || <Spinner animation="border" size="sm" />}
        </div>
      </div>
      <Formik
        onSubmit={onSubmitMoveDate}
        initialValues={{
          moveRadio: '',
          moveDateYear: '',
          moveDateMonth: '',
        }}
        validationSchema={schema}>
        {({ handleSubmit, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit}>
            <Field name="moveRadio">
              {({ field, meta }) => (
                <RadioWrapper
                  className={cx({ selected: field.value === 'more-than' })}>
                  <Form.Check
                    type="radio"
                    name={field.name}
                    id="more-less-than"
                    value="more-than"
                    label={'I moved in more than 13 months ago'}
                    onChange={e => {
                      setTouched(true);
                      field.onChange(e);
                    }}
                    onBlur={field.onBlur}
                    className={cx({ error: !!meta.error })}
                  />
                </RadioWrapper>
              )}
            </Field>
            <Field name="moveRadio">
              {({ field, meta }) => (
                <RadioWrapper
                  className={cx({ selected: field.value === 'less-than' })}>
                  <Form.Check
                    type="radio"
                    name={field.name}
                    id="radio-less-than"
                    value="less-than"
                    label={'I moved in during the last 13 months'}
                    onChange={async e => {
                      setTouched(true);
                      field.onChange(e);
                    }}
                    onBlur={field.onBlur}
                    className={cx({ error: !!meta.error })}
                  />
                  {field.value === 'less-than' && (
                    <div className="p-2 pl-5">
                      <div>
                        <p>
                          <strong>Please enter your move-in date</strong>
                        </p>
                      </div>
                      <div className="d-flex flex-row">
                        <span className="flex-fill">
                          <Field name="moveDateMonth">
                            {({ field, meta }) => (
                              <>
                                <Form.Control
                                  name={field.name}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onBlur}
                                  isValid={!meta.error && meta.touched}
                                  className={cx({
                                    error: !!meta.error && !!meta.touched,
                                  })}
                                  as="select"
                                  placeholder="Month">
                                  <option value="">Month</option>
                                  {moveMonths.map((month, index) => (
                                    <option
                                      key={`month-${month}-${index}`}
                                      value={month}>
                                      {month}
                                    </option>
                                  ))}
                                </Form.Control>
                                {meta.error && meta.touched && (
                                  <ErrorLabel label={meta.error} />
                                )}
                              </>
                            )}
                          </Field>
                        </span>
                        <span className="mt-2 px-2">/</span>
                        <span className="flex-fill">
                          <Field name="moveDateYear">
                            {({ field, meta }) => (
                              <>
                                <Form.Control
                                  name={field.name}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onBlur}
                                  isValid={!meta.error && meta.touched}
                                  className={cx({
                                    error: !!meta.error && !!meta.touched,
                                  })}
                                  as="select"
                                  placeholder="Year">
                                  <option value="">Year</option>
                                  {moveYears.map((year, index) => (
                                    <option
                                      key={`year-${year}-${index}`}
                                      value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </Form.Control>
                                {meta.error && meta.touched && (
                                  <ErrorLabel label={meta.error} />
                                )}
                              </>
                            )}
                          </Field>
                        </span>
                      </div>
                    </div>
                  )}
                </RadioWrapper>
              )}
            </Field>
            <Button
              block
              type="submit"
              variant="primary"
              disabled={shouldDisableSubmit(values, touched)}>
              {!isSubmitting ? (
                'Continue'
              ) : (
                <Spinner animation="border" size="sm" />
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MoveDateContainer;
