import { Button, Modal } from "react-bootstrap";
import { StyledModalHeader, StyledLinkButton } from "../../Styled";
import TdasLogo from "../../../assets/svg/tdas.svg";
import WhiteSpinner from "../../WhiteSpinner";

const Intro = props => (
  <>
    <StyledModalHeader closeButton>
      <img alt="Turn Down and Save" src={TdasLogo} />
    </StyledModalHeader>
    <Modal.Body className="px-4 pb-3">
      <h1 className="mb-4 text-left">Turn Down and Save, with Loop Optimise</h1>
      <p className="mb-4">
        Get rewarded for using less energy, at carbon intensive times. Our
        winter Turn Down and Save scheme rewards users for using less
        electricity when the demand is highest, in a number of national
        energy-saving events. During these events, participating households try
        to use less electricity than normal. If you’re successful, you’ll
        receive a reward for each kilowatt hour less than normal that you use.
      </p>
      <Button onClick={props.onNext} disabled={props.loadingLoopAccount} block>
        {props.loadingLoopAccount ? <WhiteSpinner /> : "Get started"}
      </Button>
      <StyledLinkButton
        className="mt-3"
        variant="link"
        onClick={props.onHide}
        block>
        No thanks, not right now
      </StyledLinkButton>
    </Modal.Body>
  </>
);
export default Intro;
