export const cleanMpan = mpan => {
  return String(mpan?.replace(/[^0-9-]/g, ''));
};

export const formatMpanForDisplay = mpan => {
  const cleanedMpan = cleanMpan(mpan);
  let formattedMpan = '';
  for (let i = 0; i < cleanedMpan.length; i++) {
    formattedMpan += cleanedMpan[i];
    if ([1, 5, 9].includes(i)) {
      formattedMpan += ' ';
    }
  }
  return formattedMpan.trim();
};
