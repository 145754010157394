/* eslint-disable max-len */
const PaymentCard = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={70}
    height={58}
    {...props}>
    <defs>
      <linearGradient id="c" x1="5.391%" x2="94.609%" y1="100%" y2="0%">
        <stop offset="0%" stopColor="#0BA360" />
        <stop offset="100%" stopColor="#3CBA92" />
      </linearGradient>
      <path id="a" d="M0 0h70v49.455H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 8)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#0C1F28"
          d="M63.293 49.455H6.707A6.707 6.707 0 0 1 0 42.748V6.708A6.707 6.707 0 0 1 6.707 0h56.586A6.707 6.707 0 0 1 70 6.707v36.04a6.707 6.707 0 0 1-6.707 6.708"
          mask="url(#b)"
        />
        <path
          fill="#8E7EAC"
          d="M58.809 31.657H11.19a2.24 2.24 0 1 1 0-4.48l47.618.001a2.24 2.24 0 1 1 0 4.48M18.35 38.59h-7.16a2.24 2.24 0 1 1 0-4.48l7.16.001a2.24 2.24 0 1 1 0 4.48m26.379 0H25.26a2.24 2.24 0 1 1 0-4.48h19.47a2.24 2.24 0 1 1 0 4.48"
        />
        <path
          fill="#584380"
          d="M61.048 14.597a2.832 2.832 0 0 1-2.824 2.824h-8.568a2.832 2.832 0 0 1-2.824-2.824V9.902a2.832 2.832 0 0 1 2.824-2.824h8.568a2.832 2.832 0 0 1 2.824 2.824v4.695Z"
        />
      </g>
      <path
        fill="url(#c)"
        stroke="#FFF"
        strokeWidth={2}
        d="m13.22.152 9.789 4.152C24.153 4.79 25.01 5.916 25 7.232c-.026 5.203-2.147 14.639-11.104 18.928-.868.41-1.877.41-2.745 0C2.19 21.87.072 12.435 0 7.232c.04-1.316.895-2.443 2.038-2.928L11.832.152a1.652 1.652 0 0 1 1.388 0Z"
        transform="translate(10 1)"
      />
      <path
        fill="#FFF"
        d="M21.148 19.042c-.374 0-.733-.148-.999-.414l-2.888-2.888a1.412 1.412 0 1 1 1.998-1.997l1.804 1.804 4.647-5.473a1.412 1.412 0 1 1 2.153 1.828l-5.638 6.642a1.412 1.412 0 0 1-1.077.498"
      />
    </g>
  </svg>
);

/* eslint-enable max-len */
export default PaymentCard;
