import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { getAnalyticsData, getUserDetails } from '../../api/Optimise';
import { AppContext } from '../../context/AppContext';
import withPageTitle from '../../hocs/withPageTitle';
import { useOnDateChange } from '../../hooks';
import GridExportsGraph from '../../ui/Dashboard/Savings/GridExportsGraph';
import GridImportsGraph from '../../ui/Dashboard/Savings/GridImportsGraph';
import SavingsSummary from '../../ui/Dashboard/Savings/SavingsSummary';
import TariffKey from '../../ui/Dashboard/Savings/TariffKey';
import {
  getSavingsTotal,
  importExportPeriodRanking,
  prepareSavingsChartData,
} from '../../utils/savingsUtils';
import WarningPanel from '../../ui/WarningPanel';
import InfoModal from '../../ui/Modal/InfoModal';
import { cheap, peak } from '../../theme/colors';
import getHasPeak from '../../utils/getHasPeak';

const SavingsContainer = () => {
  const history = useHistory();
  const [fetchingAnalyticsData, setFetchingAnalyticsData] = useState(false);
  const [fetchedAnalyticsData, setFetchedAnalyticsData] = useState(false);
  const [fetchedLatestAnalyticsData, setFetchedLatestAnalyticsData] =
    useState(false);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [latestItem, setLatestItem] = useState(null);
  const [formattedDate, setFormattedDate] = useState(
    moment().subtract(1, 'days').format('YYYY-MM-DD')
  );
  const [showNoDataModal, setShowNoDataModal] = useState(false);
  const [savingsData, setSavingsData] = useState(null);
  const [boundaryData, setBoundaryData] = useState(null);
  const [hasPeak, setHasPeak] = useState(null);
  const { userDetails, analyticsStartDate, userSub, setDatePickerWarning } =
    useContext(AppContext);

  const startDateIsToday = !!analyticsStartDate
    ? moment(analyticsStartDate).isSame(moment(), 'day')
    : true;

  const fetchAnalyticsData = useCallback(async date => {
    setFetchingAnalyticsData(true);
    try {
      const data = await getAnalyticsData(date, userSub);
      setAnalyticsData(data);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    } finally {
      setFetchingAnalyticsData(false);
      setFetchedAnalyticsData(true);
    }
  }, []);

  const fetchLatestItem = useCallback(async () => {
    const user = await getUserDetails(userSub);
    const end = moment
      .utc(user.analytics_data_range.end_date)
      .format('YYYY-MM-DD');
    try {
      const data = await getAnalyticsData(end, userSub);
      !!data && setLatestItem(data[data.length - 1]);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    } finally {
      setFetchedLatestAnalyticsData(false);
    }
  }, []);

  useEffect(() => {
    fetchAnalyticsData(formattedDate);
    fetchLatestItem();

    // Set up periodic refresh (e.g., every 5 minutes)
    const refreshInterval = setInterval(
      () => {
        fetchAnalyticsData(formattedDate);
        fetchLatestItem();
      },
      5 * 60 * 1000
    );

    return () => clearInterval(refreshInterval);
  }, [formattedDate, fetchAnalyticsData]);

  const {
    savings,
    dailyAnalyticsData,
    cheapTariffImportRate,
    middleTariffImportRate,
    peakTariffImportRate,
    cheapTariffExportRate,
    middleTariffExportRate,
    peakTariffExportRate,
  } = useMemo(() => {
    if (!analyticsData) {
      return {};
    }

    const savingsChartData = prepareSavingsChartData(analyticsData);
    const {
      processedData,
      cheapTariffImportRate,
      middleTariffImportRate,
      peakTariffImportRate,
      cheapTariffExportRate,
      middleTariffExportRate,
      peakTariffExportRate,
    } = importExportPeriodRanking(
      savingsChartData,
      userDetails?.tariffs?.import_tariff?.tariff_name,
      userDetails?.tariffs?.export_tariff?.tariff_name
    );

    return {
      savings: getSavingsTotal(analyticsData),
      dailyAnalyticsData: processedData,
      cheapTariffImportRate,
      middleTariffImportRate,
      peakTariffImportRate,
      cheapTariffExportRate,
      middleTariffExportRate,
      peakTariffExportRate,
    };
  }, [analyticsData, userDetails]);

  const colorMap = {
    'Cheap rate': cheap,
    'Day rate': 'transparent',
    'Peak rate': peak,
  };

  useEffect(() => {
    const savingsData = dailyAnalyticsData?.map(row => ({
      data: row,
      earnings: parseFloat(row.actual_export_earning),
      time: row.time,
    }));
    setSavingsData(savingsData);
    const highestDayEarnings = savingsData?.reduce(
      (acc, row) => (row.earnings > acc ? row.earnings : acc),
      0
    );
    const boundaryData = savingsData?.map(row => ({
      time: row.time,
      boundary: row?.data?.tariff_rate,
      color: colorMap[row?.data?.tariff_rate],
      value:
        row?.data?.tariff_rate == 'Cheap rate' ||
        row?.data?.tariff_rate == 'Peak rate'
          ? highestDayEarnings
          : 0,
    }));
    setBoundaryData(boundaryData);
    const hasPeak = getHasPeak(boundaryData);
    setHasPeak(hasPeak);
  }, [dailyAnalyticsData]);

  useEffect(() => {
    {
      if (startDateIsToday) {
        setDatePickerWarning(
          <>
            <WarningPanel
              title="Not enough data to show your Savings."
              body="Please wait 24 hours for the Loop Optimise calculations to be made."
              linkText="Why does Loop Optimise not have my data yet?"
              linkAction={() => setShowNoDataModal(true)}
            />

            <InfoModal
              show={showNoDataModal}
              onClose={() => setShowNoDataModal(false)}
              infoContent={
                <div>
                  Calculating relative savings can be a complex and
                  time-consuming process. For this reason, Loop Optimise
                  performs these calculations once a day (overnight) to provide
                  a summary of savings from the previous day. While this
                  approach may change in the future, a daily summary is
                  currently sufficient to show how much was saved using Loop
                  Optimise. <br />
                  <br />
                  So, when you first sign up, you may need to wait up to 24
                  hours for daily savings to be available.
                </div>
              }
            />
          </>
        );
      } else {
        setDatePickerWarning(undefined);
      }
    }
  }, [startDateIsToday]);

  const onSelectDate = useCallback(
    date => {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      history.push(`/app/savings/${moment(date).unix()}`);
      setFormattedDate(formattedDate);
    },
    [history]
  );

  useOnDateChange(onSelectDate);

  return (
    <>
      <SavingsSummary
        savings={savings}
        total={latestItem?.running_total_saving_against_dumb_battery}
        fetchedAnalyticsData={fetchedAnalyticsData}
        fetchedLatestAnalyticsData={fetchedLatestAnalyticsData}
        userStartDate={analyticsStartDate}
      />
      <GridExportsGraph
        dailyAnalyticsData={dailyAnalyticsData}
        boundaryData={boundaryData}
        savingsData={savingsData}
        hasPeak={hasPeak}
        date={formattedDate}
      />
      <GridImportsGraph
        dailyAnalyticsData={dailyAnalyticsData}
        boundaryData={boundaryData}
        savingsData={savingsData}
        hasPeak={hasPeak}
        date={formattedDate}
      />
      <TariffKey
        fetchingAnalyticsData={fetchingAnalyticsData}
        cheapTariffImportRate={cheapTariffImportRate}
        middleTariffImportRate={middleTariffImportRate}
        peakTariffImportRate={peakTariffImportRate}
        cheapTariffExportRate={cheapTariffExportRate}
        middleTariffExportRate={middleTariffExportRate}
        peakTariffExportRate={peakTariffExportRate}
        hasPeak={hasPeak}
      />
    </>
  );
};

export default withPageTitle('Loop Optimise Savings', SavingsContainer);
