import moment from 'moment';

const getDateOptions = (numberOfOptions, type, format) => {
  const options = [];
  for (let i = 0; i < numberOfOptions; i++) {
    options.push(moment().subtract(i, type).format(format));
  }
  return options;
};

export default getDateOptions;
