import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";
import PropTypes from "prop-types";
import AnimatedToggle from "./AnimatedToggle";
import { signOut } from "../utils/auth";

const StyledNavbarToggle = styled(Navbar.Toggle)`
  border: none;
`;

const MainNav = ({ history }) => (
  <>
    {/* eslint-disable-next-line react/no-children-prop */}
    <StyledNavbarToggle
      aria-controls="responsive-navbar-nav"
      children={AnimatedToggle}
    />
    <Navbar.Collapse id="responsive-navbar-nav" className="pt-3">
      <Nav className="ml-auto pb-4">
        <Nav.Link href="/account">Account</Nav.Link>
        <Nav.Link href="#" onClick={() => signOut(history)}>
          Sign Out
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </>
);

MainNav.propTypes = {
  history: PropTypes.object,
};

export default MainNav;
