import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { Intro, OptIn, Connect, Success, Error } from './TdasModalScreens';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../context/AppContext';
import CloseButton from '../../assets/svg/close-button.svg';
import { createUserTrace, getUserLoopAccount } from '../../api/Optimise';

export const MODAL_VIEWS = {
  INTRO: 'INTRO',
  OPTIN: 'OPTIN',
  CONNECT: 'CONNECT',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 640px;
  }
  .close {
    display: none;
  }
`;

const StyledAnchor = styled.a`
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 10px;
`;

const TdasModal = props => {
  const history = useHistory();
  const [loadingLoopAccount, setLoadingLoopAccount] = useState(false);
  const { tdasModalView, setTdasModalView } = useContext(AppContext);

  const renderView = modalView => {
    switch (modalView) {
      default:
        return (
          <Intro
            {...props}
            loadingLoopAccount={loadingLoopAccount}
            onNext={() => {
              createUserTrace('Begin TDAS Onboarding');
              setLoadingLoopAccount(true);
              getUserLoopAccount()
                .then(() => setTdasModalView(MODAL_VIEWS.SUCCESS))
                .catch(error => {
                  if (error.status === 404) {
                    setTdasModalView(MODAL_VIEWS.OPTIN);
                  }
                })
                .finally(() => setLoadingLoopAccount(false));
            }}
          />
        );
      case MODAL_VIEWS.OPTIN:
        return (
          <OptIn
            {...props}
            onNext={() => {
              createUserTrace('Opt In To TDAS');
              setTdasModalView(MODAL_VIEWS.CONNECT);
            }}
          />
        );
      case MODAL_VIEWS.CONNECT:
        return (
          <Connect
            {...props}
            onNext={() => {
              createUserTrace('Connect Smart Meter');
              history.push('/smart-meter');
            }}
          />
        );
      case MODAL_VIEWS.SUCCESS:
        return <Success {...props} />;
      case MODAL_VIEWS.ERROR:
        return (
          <Error
            {...props}
            onNext={() => {
              createUserTrace('TDAS Onboarding Error');
              setTdasModalView(MODAL_VIEWS.CONNECT);
            }}
          />
        );
    }
  };

  return (
    <StyledModal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      {renderView(tdasModalView)}
      <StyledAnchor onClick={props.onHide}>
        <img src={CloseButton} alt="Close" />
      </StyledAnchor>
    </StyledModal>
  );
};

export default TdasModal;
