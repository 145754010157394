import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { primary, white } from '../../theme/colors';

const StyledSwitch = styled(Form.Check)`
  margin-top: 6px;
  margin-left: 35px;

  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      background-color: ${primary};
      border-color: ${primary};
    }
  }

  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgb(255, 0, 133, 0.5);
  }

  .custom-control-label::before {
    background-color: ${white};
    transition: background-color 0.2s ease-in-out;
  }

  .custom-switch .custom-control-label::after {
    background-color: white;
  }
`;

export default StyledSwitch;
