import StarIcon from "../../../assets/svg/star-icon.svg";
import AlertBar from "../AlertBar";
import ArrowsLeft from "../../../assets/svg/arrows-left.svg";
import ArrowsRight from "../../../assets/svg/arrows-right.svg";
import styled from "styled-components";
import getStartAndEndTime from "../../../utils/getStartAndEndTime";
import moment from "moment";

const StyledSpan = styled.span`
  white-space: nowrap;
`;

const formatTime = timestamp =>
  moment.unix(timestamp).format("hA").toLowerCase();

const AlertEvent = ({ gridEvent }) => {
  const startAndEndTime = getStartAndEndTime(gridEvent?.event_info);
  return (
    <>
      <AlertBar
        label={
          <div>
            Turn Down and Save event today. Tonight:{" "}
            <StyledSpan>
              {formatTime(startAndEndTime.startTime)} to{" "}
              {formatTime(startAndEndTime.endTime)}.
            </StyledSpan>{" "}
            <StyledSpan>
              <u>Find out more.</u>
            </StyledSpan>
          </div>
        }
        icon={StarIcon}
        afterIcon={StarIcon}
        onClick={() =>
          (window.location.href =
            "https://kb.loop.homes/what-should-i-try-to-do-during-an-optimise-turn-down-and-save-event")
        }
        variant="dark"
        beforeBg={ArrowsLeft}
        afterBg={ArrowsRight}
      />
    </>
  );
};

export default AlertEvent;
