import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { light } from '../../theme/colors';

const StyledLinkButton = styled(Button)`
  color: ${light};
  font-weight: 500;
  text-decoration: none;
`;

export default StyledLinkButton;
