import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import {
  validateAddress,
  validateMpan,
  validationCodes,
} from '../../api/SmartMeter';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import terms from '../../data/terms.json';
import ErrorLabel from '../../ui/ErrorLabel';
import LoopSpinner from '../../ui/LoopSpinner';
import { cleanMpan, formatMpanForDisplay } from '../../utils/formatMpanUtil';
import { light, success, white, error } from '../../theme/colors';
import { ConsentContext } from '../../context/ConsentContext';
import TermsModal from '../../ui/Modal/TermsModal';
import checkboxEmpty from '../../assets/svg/check-box-empty.svg';
import checkboxError from '../../assets/svg/check-box-error.svg';
import checkboxFilled from '../../assets/svg/check-box-filled.svg';
import MpanWhatsThisModal from '../../ui/Modal/MpanWhatsThisModal';
import { createUserTrace } from '../../api/Optimise';

const StyledHR = styled.hr`
  height: 1px;
  border-top: solid 1px ${light};
`;

const StyledFormControl = styled(Form.Control)``;

const StyledCheckboxDiv = styled.div`
  margin-bottom: 10px;

  .form-check {
    padding-left: 0;
    background: url(${checkboxEmpty}) no-repeat top left;

    input {
      display: none;
    }
    .form-check-label {
      p {
        padding-left: 32px;
      }
    }
  }

  &.selected {
    .form-check {
      background-image: url(${checkboxFilled});
    }
  }
  &.error {
    .form-check {
      background-image: url(${checkboxError});
    }
  }

  .error-label {
    margin-left: 32px;
  }
`;

const StyledWhatsThisButton = styled(Button)`
  position: relative;
  top: -1px;
`;

const StyledFormGroup = styled(Form.Group)`
  &.user-supplied {
  }

  &.looked-up {
    .input-group {
      input.form-control {
        background: ${white};
        border-color: ${success};
        border-right: none;
      }
      .btn {
        border-left: 1px solid ${light};
        padding: 0 0 0 10px;
      }
    }
  }
`;

const StyledInputGroupText = styled(InputGroup.Text)`
  border-left: none;
  padding-top: 0;
  padding-bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: ${success};
  background: ${white};
  padding-left: 0;
`;

const StyleErrorLabelButton = styled(Button)`
  margin: 0;
  padding: 0;
  color: ${error};
  font-size: 12px;
`;

// const StyledErrorMarkdown = styled(ReactMarkdown)`
//   p {
//     margin-bottom: 0;
//   }
// `;

const MpanContainer = () => {
  const history = useHistory();
  const {
    isManualAddress,
    lookedUpMpanHash,
    lookedUpMpanObfuscated,
    lookedUpMpanKnown,
    setMpan,
    consentFlow,
    setTermsAndConditions,
  } = useContext(ConsentContext);
  const [userSuppliedMpan, setUserSuppliedMpan] = useState(!lookedUpMpanKnown);
  const [showTerms, setShowTerms] = useState(false);
  const [showWhatsThis, setShowWhatsThis] = useState(false);
  const [fullTerms, setFullTerms] = useState(undefined);

  const showFullTerms = e => {
    e.preventDefault();
    setShowTerms(true);
  };

  const onHideTerms = () => setShowTerms(false);

  const onHideWhatsThis = () => setShowWhatsThis(false);

  const onSubmit = async ({ mpan, ...values }) => {
    hideAlert();

    if (!!values.confirmTerms) {
      const termsAndConditions = {
        userSuppliedMpan,
        ...values,
        confirmTermsText: values.confirmTerms && terms.confirmTermsText,
        confirmMpanText: values.confirmMpan && terms.confirmMpanText,
        page: terms.page,
        modal: fullTerms
          ? `${terms.fullTermsTitle}\n\n${terms.fullTermsBody}`
          : 'Full terms not viewed',
      };

      setTermsAndConditions(JSON.stringify(termsAndConditions));
    }

    // if user has supplied the mpan then continue with that, otherwise if confirmed the pre-filled obfuscated mpan then
    // continue with the associated hash version
    const finalisedMpan = userSuppliedMpan ? cleanMpan(mpan) : lookedUpMpanHash;

    let response;

    if (isManualAddress || userSuppliedMpan) {
      const user = await Auth.currentAuthenticatedUser();
      response = await validateAddress(
        finalisedMpan,
        user?.attributes['custom:postcode'].replace(' ', ''),
      );
    } else {
      response = await validateMpan(finalisedMpan);
    }

    if (response?.code === validationCodes.OK) {
      setMpan(finalisedMpan);
      window.scrollTo(0, 0);
      createUserTrace('Terms MPAN', values);
      history.push(`/smart-meter/${consentFlow}/verify-address`);
    } else {
      if (response.code === validationCodes.MPAN_NOT_FOUND) {
        history.push(`/smart-meter/${consentFlow}/not-compatible`);
      } else {
        const message = !!response?.message ? '- ' + response.message : '';
        addAlert(
          'danger',
          `There was a problem submitting your MPAN number ${message}`,
        );
      }
    }
  };

  const regularMpanValidationRegex =
    /^[1-4][0-9]\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{3}$/;
  const obfuscatedMpanValidationRegex =
    /^[1-4][0-9]\s?[0-9]{4}\s?[0-9]{4}\s?---$/;

  // The regex varies depending if the mpan is obfuscated
  const mpanRegex = userSuppliedMpan
    ? regularMpanValidationRegex
    : obfuscatedMpanValidationRegex;

  // We only need confirmMpan to be required if not user supplied
  const confirmMpan = !userSuppliedMpan
    ? Yup.boolean()
        .required(
          'You must agree to the Terms and Conditions in order to proceed',
        )
        .oneOf(
          [true],
          'You must agree to the Terms and Conditions in order to proceed',
        )
    : undefined;

  // TODO: Validate the booleans
  const validationSchema = Yup.object().shape({
    mpan: Yup.string()
      .matches(mpanRegex, 'MPAN must contain 13 digits, starting with a 1 or 2')
      .required('Please enter your 13 digit MPAN number'),
    confirmTerms: Yup.boolean()
      .required(
        'You must agree to the Terms and Conditions in order to proceed',
      )
      .oneOf(
        [true],
        'You must agree to the Terms and Conditions in order to proceed',
      ),
    confirmMpan,
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(undefined);

  const addAlert = (variant, message) => {
    setShowAlert(true);
    setAlert({ variant, message });
  };

  const hideAlert = () => {
    setShowAlert(false);
    setAlert(undefined);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={{
        confirmTerms: false,
        confirmMpan: false,
        mpan: formatMpanForDisplay(lookedUpMpanObfuscated),
      }}
      validateOnChange>
      {({ handleSubmit, isValid, isSubmitting, setFieldValue, ...rest }) => {
        const onEdit = () => {
          setUserSuppliedMpan(true);
          setFieldValue('mpan', '');
          onHideWhatsThis();
        };
        return (
          <Form onSubmit={handleSubmit}>
            {showTerms && (
              <TermsModal
                showTerms={showTerms}
                onHideTerms={onHideTerms}
                setFullTerms={setFullTerms}
              />
            )}
            {showWhatsThis && (
              <MpanWhatsThisModal
                showWhatsThis={showWhatsThis}
                onHideWhatsThis={onHideWhatsThis}
                onEdit={onEdit}
              />
            )}
            <p>
              Step <strong>2</strong> of <strong>4</strong>
            </p>
            <h1 className="pb-3">Terms and Conditions</h1>
            <div onClick={showFullTerms}>
              <p>
                Loop requests your consent to access, store and process your
                energy data, tariff details and meter information, on a daily or
                more frequent basis.
              </p>
              <p>
                Read the full <a href="">Terms and Conditions</a>.
              </p>
            </div>
            <StyledHR />
            {showAlert && alert && (
              <div className="mb-4">
                <Alert
                  variant={alert.variant}
                  onClose={() => hideAlert()}
                  dismissible>
                  {alert.message}
                </Alert>
              </div>
            )}
            <Field name="confirmTerms">
              {({ field, meta }) => (
                <StyledCheckboxDiv
                  className={`text-left ${cx({
                    selected: field.value,
                    error: !!meta.error,
                  })}`}>
                  <Form.Check
                    type="checkbox"
                    name="confirmTerms"
                    id="confirm-terms-check"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    className={cx({ error: !!meta.error })}
                    label={
                      <p>
                        I confirm that I have the authority to grant consent for
                        the data mentioned above to be accessed and used in the
                        ways described.
                      </p>
                    }
                  />
                  {meta.error && meta.touched && (
                    <ErrorLabel
                      pad={false}
                      showIcon={false}
                      label={meta.error}
                    />
                  )}
                </StyledCheckboxDiv>
              )}
            </Field>
            {!userSuppliedMpan && (
              <Field name="confirmMpan">
                {({ field, meta }) => (
                  <StyledCheckboxDiv
                    className={`text-left ${cx({
                      selected: field.value,
                      error: !!meta.error,
                    })}`}>
                    <Form.Check
                      type="checkbox"
                      name="confirmMpan"
                      id="confirm-mpan-check"
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      className={cx({ error: !!meta.error })}
                      label={
                        <p>
                          Loop has pre-filled the MPAN based on the address
                          entered. I confirm that the MPAN associated with my
                          address is:
                        </p>
                      }
                    />
                    {meta.error && meta.touched && (
                      <ErrorLabel
                        pad={false}
                        showIcon={false}
                        label={meta.error}
                      />
                    )}
                  </StyledCheckboxDiv>
                )}
              </Field>
            )}
            <Field name="mpan">
              {({ field, meta }) => (
                <StyledFormGroup
                  controlId="mpan"
                  className={cx({
                    'text-left': true,
                    'user-supplied': userSuppliedMpan,
                    'looked-up': !userSuppliedMpan,
                  })}>
                  <Form.Label>
                    MPAN{' '}
                    <StyledWhatsThisButton
                      onClick={() => setShowWhatsThis(true)}
                      variant="link"
                      className="m-0 p-0">
                      What's this?
                    </StyledWhatsThisButton>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <StyledFormControl
                      value={formatMpanForDisplay(field.value)}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      disabled={!userSuppliedMpan || isSubmitting}
                      isValid={!meta.error && meta.touched}
                      placeholder="00 0000 0000 000"
                      className={cx({
                        error: !!meta.error,
                        lookedup:
                          lookedUpMpanObfuscated === cleanMpan(field.value),
                      })}
                      maxLength="16"
                    />
                    {!userSuppliedMpan && (
                      <StyledInputGroupText>
                        <Button onClick={onEdit} variant="link" className="m-0">
                          Edit
                        </Button>
                      </StyledInputGroupText>
                    )}
                  </InputGroup>
                  {userSuppliedMpan && meta.error && meta.touched && (
                    <ErrorLabel
                      label={
                        lookedUpMpanKnown ? (
                          <>
                            This MPAN doesn't look right, please try again or{' '}
                            <StyleErrorLabelButton
                              variant="link"
                              onClick={() => {
                                setUserSuppliedMpan(false);
                                setFieldValue('mpan', lookedUpMpanObfuscated);
                              }}>
                              use the previous MPAN
                            </StyleErrorLabelButton>{' '}
                            which Loop pre-filled
                          </>
                        ) : (
                          meta.error
                        )
                      }
                    />
                  )}
                </StyledFormGroup>
              )}
            </Field>

            <Form.Group>
              {!isSubmitting && userSuppliedMpan && (
                <Button
                  variant="primary"
                  block
                  type="submit"
                  disabled={!isValid || isSubmitting}>
                  Continue
                </Button>
              )}
              {!isSubmitting && !userSuppliedMpan && (
                <Button
                  variant="primary"
                  block
                  type="submit"
                  disabled={isSubmitting}
                  className="mt-2 mb-3">
                  Continue
                </Button>
              )}
              {isSubmitting && <LoopSpinner />}
            </Form.Group>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MpanContainer;
