import moment from "moment";
import getStartAndEndTime from "./getStartAndEndTime";

/**
 * Finds the next future event record from the collection using getStartAndEndTime.
 *
 * @param {Array<Object>} events - An array of events containing event info with `time` (Unix timestamp).
 * @returns {Object|null} The entire event record containing the next future event or null if none exist.
 */
function getNextFutureEvent(events) {
  if (!Array.isArray(events) || events.length === 0) {
    return null;
  }

  // Current time in seconds
  const now = moment().unix();

  // Check if the startTime is in the future
  // Find the full record of the event containing the earliest future time
  for (const event of events) {
    const startAndEndTime = getStartAndEndTime(event?.event_info);
    if (startAndEndTime.startTime > now) {
      return event;
    }
  }

  return null;
}

export default getNextFutureEvent;
