import { useContext } from "react";
import InformationPanel from "./InformationPanel";
import styled from "styled-components";
import { AppContext } from "../../../context/AppContext";
import EnergyIcon from "../../EnergyIcon";
import { TariffDirection } from "../../../api/Optimise";
import SpacerHr from "../../../ui/Styled/SpacerHr";
import { light } from "../../../theme/colors";

const StyledHr = styled.div`
  margin: 12px 0 !important;
  border-bottom: 1px solid ${light};
`;

const TariffInformation = () => {
  const { userDetails } = useContext(AppContext);
  return (
    <InformationPanel
      title="Tariff information"
      isLoading={!userDetails?.tariffs}
      loadingLines={4}>
      <div className="d-flex">
        <EnergyIcon direction={TariffDirection.IMPORT} />
        <div className="pl-2">
          <li>
            Import energy provider:{" "}
            {userDetails?.tariffs.import_tariff.supplier}
          </li>
          <li>
            Import tariff: {userDetails?.tariffs.import_tariff.tariff_name}
          </li>
        </div>
      </div>
      <StyledHr />
      <div className="d-flex">
        <EnergyIcon direction={TariffDirection.EXPORT} />
        <div className="pl-2">
          <li>
            Export energy provider:{" "}
            {userDetails?.tariffs.export_tariff.supplier}
          </li>
          <li>
            Export tariff: {userDetails?.tariffs.export_tariff.tariff_name}
          </li>
        </div>
      </div>
    </InformationPanel>
  );
};

export default TariffInformation;
