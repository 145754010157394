import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import CheckMark from '../../assets/svg/disc/check-disc.svg';
import { useHistory } from 'react-router-dom';

const StyledImg = styled.img`
  width: 50px;

  @media (min-width: 576px) {
    width: 80px;
  }
`;

const ConnectedContainer = () => {
  const history = useHistory();
  return (
    <>
      <h1 className="mb-3">Connected to your system</h1>
      <div className="mt-1 mb-4">
        <StyledImg src={CheckMark} alt="Success checkmark" />
      </div>
      <p className="mb-0">
        Loop Optimise has successfully connected to your inverter.
      </p>
      <Button className="mb-2" onClick={() => history.push('/app/today')}>
        Continue
      </Button>
    </>
  );
};

export default ConnectedContainer;
