import InformationPanel from './InformationPanel';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../../context/AppContext';
import { updateUserDetails } from '../../../api/Optimise';
import BasicSpinner from '../../BasicSpinner';
import PercentageInput from '../../PercentageInput';
import RippleLoaderLines from '../../RippleLoaderLines';

const SpinnerWrapper = styled.div`
  padding-top: 14px;
`;

const MinimumSOCSettings = ({ loadUser, setShowError }) => {
  const { userDetails, userSub } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingCount, setIsSavingCount] = useState(0);

  useEffect(() => {
    if (!!userDetails) {
      setIsLoading(false);
    }
  }, [userDetails]);

  const incrementSavingCount = () => {
    setIsSavingCount(prevCount => prevCount + 1);
  };

  const decrementSavingCount = () => {
    setIsSavingCount(prevCount => Math.max(prevCount - 1, 0));
  };

  const onSubmit = batterySOCLowerLimit => {
    incrementSavingCount();
    updateUserDetails(
      {
        battery_soc_lower_limit: batterySOCLowerLimit,
        battery_soc_upper_limit: 100,
        simulation_mode: userDetails?.simulation_mode,
      },
      userSub,
    )
      .then(() => {
        decrementSavingCount();
        loadUser();
      })
      .catch(e => {
        console.log(e);
        decrementSavingCount();
        setShowError(true);
      });
  };

  return (
    <InformationPanel title="Minimum battery discharge value">
      <li>
        Set the lowest level your battery will discharge to. By default, this is
        set to 10% for battery health. If you want to store more for emergency
        reasons you can, but it will reduce the impact of Loop Optimise.
        {isLoading ? (
          <RippleLoaderLines lines={1} className="my-3" />
        ) : (
          <div className="d-flex align-items-center">
            <PercentageInput
              onUpdate={onSubmit}
              initialValue={userDetails?.battery_details.soc_lower_limit}
              disabled={isLoading}
            />
            {!!isSavingCount && (
              <SpinnerWrapper>
                <BasicSpinner />
              </SpinnerWrapper>
            )}
          </div>
        )}
      </li>
    </InformationPanel>
  );
};

export default MinimumSOCSettings;
