import awsmobile from '../config/aws-exports.js';
import apiRequest from '../utils/apiRequest';

/**
 * Set store item by key
 * @param {string} key
 * @param {object} data
 * @return {Promise<any>}
 */
export const setStoreByKey = async (key, data) =>
  await apiRequest(
    `${awsmobile.trustpower_endpoint_auth}/store/${key}`,
    'PUT',
    data,
  );

/**
 * Get store item by key
 * @param {string} key
 * @return {Promise<any>}
 */
export const getStoreByKey = async key =>
  await apiRequest(`${awsmobile.trustpower_endpoint_auth}/store/${key}`);

/**
 * Get gas usage by resolution
 * @param {string} resolution
 * @return {Promise<any>}
 */
export const getGasUsageByResolution = async resolution =>
  await apiRequest(
    `${awsmobile.trustpower_endpoint_auth}/usage/gas/${resolution}`,
  );

/**
 * Delete user account or remove user address
 * @param {object} reason
 * @param {boolean} softDelete
 * @return {Promise<any>}
 */
export const deleteUser = async (reason, softDelete = false) =>
  await apiRequest(
    `${awsmobile.trustpower_endpoint_auth}/user?soft_delete=${softDelete}`,
    'DELETE',
    { reason },
  );

/**
 * Get user smartmeter details
 * @return {Promise<any>}
 */
export const getUserDetails = async () =>
  await apiRequest(
    `${awsmobile.trustpower_endpoint_auth}/smart_meter/user/details`,
  );
