import { Button, Form, Modal } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import cx from 'classnames';
import { StyledModalHeader, StyledLinkButton } from '../../Styled';
import ErrorLabel from '../../../ui/ErrorLabel';
import TdasLogo from '../../../assets/svg/tdas.svg';
import checkboxEmpty from '../../../assets/svg/check-box-empty.svg';
import checkboxError from '../../../assets/svg/check-box-error.svg';
import checkboxFilled from '../../../assets/svg/check-box-filled.svg';

const StyledDiv = styled.div`
  p:last-child {
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    padding-left: 18px;

    li {
      margin-bottom: 8px;

      &::before {
        content: '\\2022';
        color: #ff0085;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 20px;
      }
    }
  }

  &.terms-div {
    cursor: pointer;
  }

  .form-check {
    padding-left: 0;
    background: url(${checkboxEmpty}) no-repeat top left;

    input {
      display: none;
    }
    .form-check-label {
      padding-left: 32px;
    }
  }

  &.selected {
    .form-check {
      background-image: url(${checkboxFilled});
    }
  }
  &.error {
    .form-check {
      background-image: url(${checkboxError});
    }
  }

  .error-label {
    margin-left: 32px;
  }
`;

const validationSchema = Yup.object().shape({
  optIn: Yup.boolean()
    .required('Please check the box to take part')
    .oneOf([true], 'Please check the box to take part'),
  terms: Yup.boolean()
    .required("Please check the box to accept Loop's Terms and Conditions")
    .oneOf(
      [true],
      "Please check the box to accept Loop's Terms and Conditions",
    ),
  optOutUnderstood: Yup.boolean()
    .required(
      'Please acknowledge that you understand that Loop Optimise will opt you out of some events',
    )
    .oneOf(
      [true],
      'Please acknowledge that you understand that Loop Optimise will opt you out of some events',
    ),
});

const OptIn = props => {
  const onSubmit = props.onNext;
  return (
    <>
      <StyledModalHeader closeButton>
        <img src={TdasLogo} alt="Turn Down and Save" />
      </StyledModalHeader>
      <Modal.Body className="px-4 pb-3">
        <h1 className="mb-4 text-left">Opt in to Turn Down and Save events</h1>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={{
            optIn: false,
            terms: false,
            optOutUnderstood: false,
          }}>
          {({ handleSubmit, isValid, isSubmitting, setFieldTouched }) => (
            <Form onSubmit={handleSubmit}>
              <p className="mb-4">
                Please confirm that you want to take part in all Turn Down and
                Save events:
              </p>
              <div className="mb-4">
                <Field name="optIn">
                  {({ field, meta }) => (
                    <StyledDiv
                      className={`text-left ${cx({
                        selected: field.value,
                        error: !!meta.error,
                      })}`}>
                      <Form.Check
                        type="checkbox"
                        name="optIn"
                        id="opt-in"
                        onChange={e => {
                          field.onChange(e);
                          setFieldTouched('optIn', true, false);
                        }}
                        onBlur={() => setFieldTouched('optIn', true)}
                        className={cx({ error: !!meta.error })}
                        label="Yes, I want to take part in the Turn Down and Save events"
                      />
                      {meta.error && meta.touched && (
                        <ErrorLabel label={meta.error} />
                      )}
                    </StyledDiv>
                  )}
                </Field>
              </div>
              <div className="mb-4">
                <Field name="terms">
                  {({ field, meta }) => (
                    <StyledDiv
                      className={`text-left ${cx({
                        selected: field.value,
                        error: !!meta.error,
                      })}`}>
                      <Form.Check
                        type="checkbox"
                        name="terms"
                        id="terms"
                        onChange={e => {
                          field.onChange(e);
                          setFieldTouched('terms', true, false);
                        }}
                        onBlur={() => setFieldTouched('terms', true)}
                        className={cx({ error: !!meta.error })}
                        label={
                          <>
                            I accept Loop’s{' '}
                            <a
                              href="https://loop.homes/policies/terms-of-use"
                              target="_blank">
                              Terms and Conditions
                            </a>
                          </>
                        }
                      />
                      {meta.error && meta.touched && (
                        <ErrorLabel label={meta.error} />
                      )}
                    </StyledDiv>
                  )}
                </Field>
              </div>
              <div className="mb-4">
                <Field name="optOutUnderstood">
                  {({ field, meta }) => (
                    <StyledDiv
                      className={`text-left ${cx({
                        selected: field.value,
                        error: !!meta.error,
                      })}`}>
                      <Form.Check
                        type="checkbox"
                        name="optOutUnderstood"
                        id="optOutUnderstood"
                        onChange={e => {
                          field.onChange(e);
                          setFieldTouched('optOutUnderstood', true, false);
                        }}
                        onBlur={() => setFieldTouched('optOutUnderstood', true)}
                        className={cx({ error: !!meta.error })}
                        label={
                          <>
                            I understand that Loop Optimise will opt me out of
                            some events if they're not going to be beneficial to
                            me.{' '}
                            <a
                              href="https://kb.loop.homes/why-as-an-optimise-user-wasnt-i-included-in-a-tdas-event"
                              target="_blank">
                              Find out more
                            </a>
                          </>
                        }
                      />
                      {meta.error && meta.touched && (
                        <ErrorLabel label={meta.error} />
                      )}
                    </StyledDiv>
                  )}
                </Field>
              </div>
              <Button
                onClick={handleSubmit}
                disabled={!isValid || isSubmitting}
                block
                type="submit">
                Confirm
              </Button>
            </Form>
          )}
        </Formik>
        <StyledLinkButton
          className="mt-3"
          variant="link"
          onClick={props.onHide}
          block>
          No thanks, not right now
        </StyledLinkButton>
      </Modal.Body>
    </>
  );
};
export default OptIn;
