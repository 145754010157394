import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import LoginCard from '../ui/LoginCard';
import {
  MoveDateContainer,
  MpanContainer,
  NotCompatibleContainer,
  MeterCodeContainer,
  ErrorContainer,
  CardDetailsContainer,
  ConnectingContainer,
  CannotConfirmContainer,
  SystemUnavailableContainer,
  VerifyContainer,
} from './SmartMeter';
import { getCurrentUserInfo } from '../utils/auth';
import { ConsentContext, ConsentFlows } from '../context/ConsentContext';
import AppLayout from '../ui/Layout/AppLayout';

const SmartMeterContainer = () => {
  const {
    setHasRegisterMpanTrustedConsent,
    setHasOnboarded,
    setUser,
    consentFlow,
    setConsentFlow,
  } = useContext(ConsentContext);

  useEffect(() => {
    // re-initialise onboarding control vars
    setHasRegisterMpanTrustedConsent(false);
    setHasOnboarded(false);
    setConsentFlow(ConsentFlows.ONBOARDING);
    getCurrentUserInfo().then(res => {
      if (res) {
        setUser(res);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppLayout>
      <LoginCard>
        <Switch>
          <Route path={`/smart-meter`} exact>
            <Redirect to={`/smart-meter/${consentFlow}/move-date`} />
          </Route>
          <Route path={`/smart-meter/${consentFlow}/move-date`} exact>
            <MoveDateContainer />
          </Route>
          <Route path={`/smart-meter/${consentFlow}/mpan`} exact>
            <MpanContainer />
          </Route>
          <Route path={`/smart-meter/${consentFlow}/not-compatible`} exact>
            <NotCompatibleContainer />
          </Route>
          <Route path={`/smart-meter/${consentFlow}/verify-address`} exact>
            <VerifyContainer />
          </Route>
          <Route path={`/smart-meter/${consentFlow}/card-details`} exact>
            <CardDetailsContainer />
          </Route>
          <Route path={`/smart-meter/${consentFlow}/connecting`} exact>
            <ConnectingContainer />
          </Route>
          <Route path={`/smart-meter/${consentFlow}/cannot-confirm`} exact>
            <CannotConfirmContainer />
          </Route>
          <Route path={`/smart-meter/${consentFlow}/meter-code`} exact>
            <MeterCodeContainer />
          </Route>
          <Route
            path={`/smart-meter/${consentFlow}/error`}
            component={ErrorContainer}
            exact
          />
          <Route
            path={`/smart-meter/${consentFlow}/system-unavailable`}
            component={SystemUnavailableContainer}
            exact
          />
          <Route>
            <Redirect to="/not-found" />
          </Route>
        </Switch>
      </LoginCard>
    </AppLayout>
  );
};

export default SmartMeterContainer;
