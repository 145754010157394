import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import cx from 'classnames';
import ErrorLabel from '../../ui/ErrorLabel';
import LoopSpinner from '../../ui/LoopSpinner';
import { onboardingPropTypes } from '../../propTypes';
import { OnboardingContext } from '../../context/OnboardingContext';
import { getUnitList, getUnit } from '../../utils/dataUtils';
import { EXPORT_LIMIT } from '../../utils/getBatteryMaxDischargePower';
import inverterSvg from '../../assets/svg/inverter.svg';
import batterySvg from '../../assets/svg/today/battery.svg';
import {
  createUserTrace,
  getSupportedHardware,
  HardwareTypes,
} from '../../api/Optimise';
import BasicSpinner from '../../ui/BasicSpinner';

const StyledFormContainer = styled.div`
  margin-top: 2rem;
  text-align: left;
`;

const GroupWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }
`;

const StyledIcon = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.icon === 'inverter'
      ? 'linear-gradient(to right, #e5098b, #ff594f)'
      : 'linear-gradient(to right, #2747b1, #6433a5)'};

  @media (min-width: 576px) {
    width: 60px;
    height: 60px;
  }

  img {
    width: 20px;
    height: 20px;

    @media (min-width: 576px) {
      width: 30px;
      height: 30px;
    }
  }
`;

const StyledCard = styled.div`
  border: 2px solid rgb(175, 182, 185) !important;
  margin-bottom: 1rem;
  box-shadow: none !important;
  border-radius: 4px !important;
`;

const StyledCardBody = styled(Card.Body)`
  padding: 1rem;
  background-color: white;
  border-top: 2px solid rgb(175, 182, 185) !important;
`;

const EquipmentDetailsContainer = () => {
  const history = useHistory();
  const {
    inverterManufacturer,
    setInverterManufacturer,
    setProgressPercentage,
    inverter,
    setInverter,
    batteryManufacturer,
    setBatteryManufacturer,
    battery,
    setBattery,
  } = useContext(OnboardingContext);

  const [inverterUnitList, setInverterUnitList] = useState([]);
  const [batteryUnitList, setBatteryUnitList] = useState([]);
  const [batteryConfigList, setBatteryConfigList] = useState(undefined);
  const [inverterConfigList, setInverterConfigList] = useState(undefined);

  const validationSchema = Yup.object({
    inverterManufacturer: Yup.string()
      .trim()
      .required('Please select your inverter manufacturer'),
    inverterUnitName: Yup.string()
      .trim()
      .required('Please select your inverter model'),
    batteryManufacturer: Yup.string()
      .trim()
      .required('Please select your battery manufacturer'),
    batteryUnitName: Yup.string()
      .trim()
      .required('Please select your battery model'),
  });

  const selectInverterManufacturer = manufacturer => {
    setInverterManufacturer(manufacturer);
    setInverterUnitList(
      getUnitList(inverterConfigList?.manufacturer_list, manufacturer),
    );
  };

  const selectBatteryManufacturer = manufacturer => {
    setBatteryManufacturer(manufacturer);
    setBatteryUnitList(
      getUnitList(batteryConfigList?.manufacturer_list, manufacturer),
    );
  };

  const onSubmit = ({ inverterUnitName, batteryUnitName }) => {
    const selectedInverter = getUnit(inverterUnitList, inverterUnitName);
    setInverter(selectedInverter);
    const selectedBattery = getUnit(batteryUnitList, batteryUnitName);
    setBattery(selectedBattery);
    const batteryMaxChargePower = Math.min(
      selectedBattery?.unit_details?.battery_max_charge_power,
      selectedInverter?.unit_details?.battery_max_charge_power,
    );
    const batteryMaxDischargePower = Math.min(
      selectedBattery?.unit_details?.battery_max_discharge_power,
      selectedInverter?.unit_details?.battery_max_discharge_power,
    );

    createUserTrace('Equipment', {
      battery_capacity: selectedBattery?.unit_details?.battery_capacity,
      battery_degradation_cost:
        selectedInverter?.unit_details?.battery_degradation_cost,
      battery_efficiency: selectedInverter?.unit_details?.battery_efficiency,
      batteryMaxChargePower,
      battery_max_discharge_power: batteryMaxDischargePower,
      hardware_details: {
        battery: {
          manufacturer: batteryManufacturer,
          model: selectedBattery?.unit_name,
        },
        inverter: {
          manufacturer: inverterManufacturer,
          model: selectedInverter?.unit_name,
        },
      },
    });

    if (
      selectedInverter.unit_details.battery_max_discharge_power > EXPORT_LIMIT
    ) {
      history.push('/onboarding/export-limit');
    } else {
      history.push(`/onboarding/connection/${inverterManufacturer}`);
    }
  };

  useEffect(() => {
    setProgressPercentage(70);
  }, []);

  useEffect(() => {
    const asyncUseEffect = async () => {
      const supportedInverters = await getSupportedHardware(
        HardwareTypes.INVERTER,
      );
      setInverterConfigList(supportedInverters);
    };
    asyncUseEffect();
  }, []);

  useEffect(() => {
    if (inverterManufacturer) {
      selectInverterManufacturer(inverterManufacturer);
    }
  }, [inverterConfigList]);

  useEffect(() => {
    const asyncUseEffect = async () => {
      const supportedBatteries = await getSupportedHardware(
        HardwareTypes.BATTERY,
      );
      setBatteryConfigList(supportedBatteries);
    };
    asyncUseEffect();
  }, []);

  useEffect(() => {
    if (batteryManufacturer) {
      selectBatteryManufacturer(batteryManufacturer);
    }
  }, [batteryConfigList]);

  return (
    <>
      <h1 className="mb-3">Your inverter and battery details</h1>
      <div>
        <p>Please select your inverter and battery details.</p>
        <StyledFormContainer>
          <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
              inverterManufacturer,
              inverterUnitName: inverter?.unit_name,
              batteryManufacturer,
              batteryUnitName: battery?.unit_name,
            }}
            validateOnChange>
            {({ handleSubmit, isValid, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <GroupWrapper>
                  <StyledCard>
                    <StyledHeader>
                      <h4>Inverter Details</h4>
                      <StyledIcon icon="inverter">
                        <img src={inverterSvg} alt="Inverter" />
                      </StyledIcon>
                    </StyledHeader>
                    <div>
                      <StyledCardBody>
                        <Field name="inverterManufacturer">
                          {({ field, meta }) => (
                            <Form.Group controlId="inverterManufacturer">
                              <Form.Label>
                                Select inverter manufacturer
                                {!inverterConfigList && <BasicSpinner />}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                size="md"
                                value={field.value}
                                onChange={e => {
                                  selectInverterManufacturer(e.target.value);
                                  field.onChange(e);
                                }}
                                onBlur={field.onBlur}
                                isValid={!meta.error && meta.touched}
                                placeholder="Select inverter manufacturer"
                                className={cx({ error: !!meta.error })}
                                disabled={!inverterConfigList}>
                                <optgroup>
                                  <option value="" key="manufacturer-select">
                                    Select inverter manufacturer
                                  </option>
                                  {inverterConfigList?.manufacturer_list?.map(
                                    ({ manufacturer }, index) => (
                                      <option
                                        key={`manufacturer-${index}`}
                                        value={manufacturer}>
                                        {manufacturer}
                                      </option>
                                    ),
                                  )}
                                </optgroup>
                              </Form.Control>
                              {meta.error && meta.touched && (
                                <ErrorLabel label={meta.error} />
                              )}
                            </Form.Group>
                          )}
                        </Field>
                        <Form.Group className="no-address-text">
                          <div>
                            Manufacturer not shown?
                            <Button
                              variant="link"
                              href="https://share.hsforms.com/1gsc8DNuRSkObygSHhibu0A2uro2"
                              target="_blank"
                              className="p-0 no-address-text ml-1">
                              Let us know
                            </Button>
                          </div>
                        </Form.Group>
                        <Field name="inverterUnitName">
                          {({ field, meta }) => (
                            <Form.Group controlId="inverterUnitName">
                              <Form.Label>Select inverter model</Form.Label>
                              <Form.Control
                                as="select"
                                size="md"
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                isValid={!meta.error && meta.touched}
                                placeholder="Select inverter model"
                                disabled={!inverterUnitList?.length}
                                className={cx({ error: !!meta.error })}>
                                <optgroup>
                                  <option value="" key="inverter-select">
                                    Select inverter model
                                  </option>
                                  {inverterUnitList?.map(
                                    ({ unit_name }, index) => (
                                      <option
                                        key={`model-${index}`}
                                        value={unit_name}>
                                        {unit_name}
                                      </option>
                                    ),
                                  )}
                                </optgroup>
                              </Form.Control>
                              {meta.error && meta.touched && (
                                <ErrorLabel label={meta.error} />
                              )}
                            </Form.Group>
                          )}
                        </Field>
                      </StyledCardBody>
                    </div>
                  </StyledCard>
                </GroupWrapper>

                <GroupWrapper>
                  <StyledCard>
                    <StyledHeader>
                      <h4>Battery Details</h4>
                      <StyledIcon icon="battery">
                        <img src={batterySvg} alt="Battery" />
                      </StyledIcon>
                    </StyledHeader>
                    <div>
                      <StyledCardBody>
                        <Field name="batteryManufacturer">
                          {({ field, meta }) => (
                            <Form.Group controlId="batteryManufacturer">
                              <Form.Label>
                                Select battery manufacturer
                                {!batteryConfigList && <BasicSpinner />}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                size="md"
                                value={field.value}
                                onChange={e => {
                                  selectBatteryManufacturer(e.target.value);
                                  field.onChange(e);
                                }}
                                onBlur={field.onBlur}
                                isValid={!meta.error && meta.touched}
                                placeholder="Select battery manufacturer?"
                                className={cx({ error: !!meta.error })}
                                disabled={!batteryConfigList}>
                                <optgroup>
                                  <option value="" key="manufacturer-select">
                                    Select battery manufacturer
                                  </option>
                                  {batteryConfigList?.manufacturer_list?.map(
                                    ({ manufacturer }, index) => (
                                      <option
                                        key={`manufacturer-${index}`}
                                        value={manufacturer}>
                                        {manufacturer}
                                      </option>
                                    ),
                                  )}
                                </optgroup>
                              </Form.Control>
                              {meta.error && meta.touched && (
                                <ErrorLabel label={meta.error} />
                              )}
                            </Form.Group>
                          )}
                        </Field>
                        <Form.Group className="no-address-text">
                          <div>
                            Manufacturer not shown?
                            <Button
                              variant="link"
                              href="https://share.hsforms.com/1ads6NpkKSwWY7IjuyzWgIQ2uro2"
                              target="_blank"
                              className="p-0 no-address-text ml-1">
                              Let us know
                            </Button>
                          </div>
                        </Form.Group>
                        <Field name="batteryUnitName">
                          {({ field, meta }) => (
                            <Form.Group controlId="batteryUnitName">
                              <Form.Label>Select battery model</Form.Label>
                              <Form.Control
                                as="select"
                                size="md"
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                isValid={!meta.error && meta.touched}
                                placeholder="Select battery model"
                                disabled={!batteryUnitList?.length}
                                className={cx({ error: !!meta.error })}>
                                <optgroup>
                                  <option value="" key="battery-select">
                                    Select battery model
                                  </option>
                                  {batteryUnitList?.map(
                                    ({ unit_name }, index) => (
                                      <option
                                        key={`model-${index}`}
                                        value={unit_name}>
                                        {unit_name}
                                      </option>
                                    ),
                                  )}
                                </optgroup>
                              </Form.Control>
                              {meta.error && meta.touched && (
                                <ErrorLabel label={meta.error} />
                              )}
                            </Form.Group>
                          )}
                        </Field>
                      </StyledCardBody>
                    </div>
                  </StyledCard>
                </GroupWrapper>

                <Form.Group>
                  {!isSubmitting ? (
                    <Button
                      variant="primary"
                      disabled={!isValid || isSubmitting}
                      block
                      type="submit">
                      Continue
                    </Button>
                  ) : (
                    <LoopSpinner />
                  )}
                </Form.Group>
              </Form>
            )}
          </Formik>
        </StyledFormContainer>
      </div>
    </>
  );
};

EquipmentDetailsContainer.propTypes = {
  ...onboardingPropTypes,
};

export default EquipmentDetailsContainer;
