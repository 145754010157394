import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import {
  AdminContainer,
  TodayContainer,
  SavingsContainer,
  EnergyFlowContainer,
  SettingsContainer,
  HelpContainer,
  NotFoundContainer,
} from "./App";
import AppLayout from "../ui/Layout/AppLayout";
import AppCard from "../ui/AppCard";
import DatePickerLayout from "../ui/Layout/DatePickerLayout";
import { useContext, useEffect } from "react";
import { Auth } from "aws-amplify";
import { AppContext } from "../context/AppContext";
import { DisconnectAlert, EventAlert } from "../ui/Styled/AlertBarPreset";
import { getGridCampaigns, tdasCampaignId } from "../api/Optimise";
import getCampaignById from "../utils/getCampaignById";

const DatePickerRoutes = () => (
  <DatePickerLayout>
    <Switch>
      <Route
        path="/app/savings/:timestamp?"
        component={SavingsContainer}
        exact
      />
      <Route
        path="/app/energy-flow/:timestamp?"
        component={EnergyFlowContainer}
        exact
      />
    </Switch>
  </DatePickerLayout>
);

const AppContainer = () => {
  const history = useHistory();
  const {
    userSub,
    isAdmin,
    setIsAdmin,
    campaigns,
    setCampaigns,
    setTdasCampaign,
    setTdasIsActive,
    gridEvent,
  } = useContext(AppContext);

  useEffect(() => {
    const asyncUseEffect = async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (!!user?.attributes && "custom:admin_secret" in user.attributes) {
        setIsAdmin(true);
        if (!userSub) {
          history.push("/app/admin");
        }
      }
    };
    asyncUseEffect();
  }, []);

  useEffect(() => {
    getGridCampaigns()
      .then(campaigns => setCampaigns(campaigns))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    if (campaigns?.length) {
      const tdasCampaign = getCampaignById(campaigns, tdasCampaignId);
      setTdasCampaign(tdasCampaign);
      setTdasIsActive(tdasCampaign?.is_active);
    }
  }, [campaigns]);

  return (
    <>
      {false && <DisconnectAlert />}
      {gridEvent && <EventAlert gridEvent={gridEvent} />}
      <AppLayout backgroundTint="blue" hideSupport>
        <Switch>
          {isAdmin && (
            <Route path="/app/admin" component={AdminContainer} exact />
          )}
          <Route path="/app/*">
            <AppCard>
              <Switch>
                <Route path="/app/today" component={TodayContainer} />
                <Route
                  path={["/app/savings", "/app/energy-flow"]}
                  component={DatePickerRoutes}
                />
                <Route
                  path="/app/settings"
                  component={SettingsContainer}
                  exact
                />
                <Route path="/app/help" component={HelpContainer} exact />
                <Route path="*" component={NotFoundContainer} />
              </Switch>
            </AppCard>
          </Route>
          <Route path="/app" exact>
            <Redirect to="/app/today" />
          </Route>
        </Switch>
      </AppLayout>
    </>
  );
};

export default AppContainer;
