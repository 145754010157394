import { Button, Modal } from 'react-bootstrap';
import { StyledModalHeader, StyledLinkButton } from '../../Styled';
import TdasLogoV2 from '../../../assets/svg/tdas-v2.svg';
import { warningGradient } from '../../../theme/colors';

const Error = ({ onNext, onHide }) => (
  <>
    <StyledModalHeader background={warningGradient} closeButton>
      <img src={TdasLogoV2} alt="Turn Down and Save" />
    </StyledModalHeader>
    <Modal.Body className="px-4 pb-3">
      <h1 className="mb-4 text-left">We can’t access your smart meter data</h1>
      <p className="mb-4">
        Before we can sign you up to Turn Down and Save, Loop needs to connect
        to your smart meter.
      </p>
      <Button className="mb-3" onClick={onNext} block>
        Try Again
      </Button>
      <StyledLinkButton className="mt-3" variant="link" onClick={onHide} block>
        Close
      </StyledLinkButton>
    </Modal.Body>
  </>
);
export default Error;
