import styled from "styled-components";
import Import from "../assets/svg/icon/import.svg";
import Export from "../assets/svg/icon/export.svg";
import { electricityGradient, secondaryGradient } from "../theme/colors";
import { TariffDirection } from "../api/Optimise";

const StyledWrapper = styled.div`
  background: ${props => props.background};
  width: 60px;
  height: 60px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EnergyIcon = ({ direction }) => (
  <StyledWrapper
    background={
      direction === TariffDirection.IMPORT
        ? electricityGradient
        : secondaryGradient
    }>
    <img src={direction === TariffDirection.IMPORT ? Import : Export} />
  </StyledWrapper>
);

export default EnergyIcon;
